import { useState } from 'react'

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState(null)

  const setModal = (content = null) => {
    setIsOpen(true)
    setContent(content)
  }

  const closeModal = () => {
    setIsOpen(false)
    setContent(null)
  }

  return { isOpen, setModal, closeModal, content }
}
