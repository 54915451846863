import React, { useState } from 'react'
import { NavLink as Link } from 'react-router-dom'
import { combinedStyles, styles } from './styles'
import classnames from 'classnames'

export const SubNav = ({
  defaultChildProps = { path: '', name: '' },
  children,
}) => {
  const [open, setOpen] = useState(false)
  return (
    <div
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className='relative mr-8 ml-3'>
      <Link to={defaultChildProps.path} className={combinedStyles.parentLink}>
        {defaultChildProps.name}
      </Link>
      {open && (
        <div
          className={classnames(
            styles.link.desktopChildrenLinkContainer,
            'transform',
            /about/i.test(defaultChildProps.name)
              ? '-translate-x-16'
              : '-translate-x-7'
          )}>
          {children}
        </div>
      )}
    </div>
  )
}
export default SubNav
