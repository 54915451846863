const Close = ({ defs, fill, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 9.6 9.6'
      fill={fill || 'currentColor'}
      {...props}
    >
      {defs}
      <path
        d='m5.818 100.8 3.077-3.077.634-.634a.24.24 0 0 0 0-.339l-.679-.679a.24.24 0 0 0-.339 0L4.8 99.782 1.089 96.07a.24.24 0 0 0-.339 0l-.679.679a.24.24 0 0 0 0 .339l3.711 3.712-3.712 3.711a.24.24 0 0 0 0 .339l.679.679a.24.24 0 0 0 .339 0l3.712-3.711 3.077 3.077.634.634a.24.24 0 0 0 .339 0l.679-.679a.24.24 0 0 0 0-.339z'
        transform='translate(0 -96)'
      />
    </svg>
  )
}

export default Close
