import {
  PROMO_VERSION
} from "../actions/types";


const initialState = {
  promo: false,
  email: null,
  error: null,
};

export default function promoReducer(state = initialState, {
  type,
  payload
}) {
  switch (type) {
    case PROMO_VERSION.START:
      return {
        ...state,
        promo: payload,
      };
    case PROMO_VERSION.SUCCESS: {
      return {
        ...state,
        status: "success",
        email: payload,
      };
    }
    case PROMO_VERSION.FAIL: {
      return {
        ...state,
        error: payload,
        status: "error",
      };
    }
    case PROMO_VERSION.TRIGGER: {
      return {
        ...state,
        status: "loading",
      };
    }

    default:
      return state;
  }
}