import classnames from 'classnames'
import { string, func } from 'prop-types'

const TextLink = ({ className, children, onClick, colorClass }) => {
  return (
    <div
      className={classnames(
        'text-787878 font-secondary-regular font-normal text-12 sm:text-18 underline uppercase cursor-pointer',
        className,
        colorClass
      )}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
export default TextLink

TextLink.defaultProps = {}

TextLink.propTypes = {
  children: string,
  className: string,
  onClick: func,
}
