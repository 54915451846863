const Shield = ({ fill, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 143.266 163.733'
      fill={fill || 'currentColor'}
      {...props}
    >
      <path d='M134.45 26.763 77.143 1.18a13.454 13.454 0 0 0-11.02 0L8.817 26.763A15.4 15.4 0 0 0 0 40.933c0 63.475 34.173 107.357 66.123 121.62a13.454 13.454 0 0 0 11.02 0c25.551-11.407 66.123-50.856 66.123-121.62a15.4 15.4 0 0 0-8.816-14.17zM10.233 40.933a5.212 5.212 0 0 1 2.757-4.826l43.293-19.328V46.05H10.4c-.052-1.708-.167-3.377-.167-5.117zm46.05 103.836c-17.835-13.39-34.39-36.818-41.851-68.02h41.851zm30.7-.179V76.75h41.982c-7.965 33.636-26.274 55.791-41.982 67.84zm44-78.074H76.75v84.805a55.298 55.298 0 0 1-3.777 1.887 3.228 3.228 0 0 1-2.677 0c-1.254-.56-2.517-1.241-3.777-1.906V66.517H12.373q-.836-5.093-1.327-10.233h55.471V12.213l3.777-1.685a3.216 3.216 0 0 1 2.677 0l3.78 1.685v44.07h55.5c-.331 3.502-.737 6.93-1.271 10.234zm1.868-20.467H86.983v-29.27l43.3 19.328a5.212 5.212 0 0 1 2.753 4.826c0 1.759-.134 3.4-.185 5.117z' />
    </svg>
  )
}

export default Shield
