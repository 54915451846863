import {
  CHANGE_VIDEO_FORMAT,
} from "../actions/types";

const initialState = {
  video: null,
  status: null,
  error: null,
};

export default function videoReducer(state = initialState, { type, payload }) {
  switch (type) {
    case CHANGE_VIDEO_FORMAT.TRIGGER: {
      return {
        ...state,
        status: "loading",
      };
    }
    case CHANGE_VIDEO_FORMAT.SUCCESS: {
      return {
        error: null,
        video: payload.data,
        status: "success",
      };
    }
    case CHANGE_VIDEO_FORMAT.FAIL: {
      return {
        ...state,
        status: "error",
        error: payload,
      };
    }
    default:
      return state;
  }
}
