const Watermarks = ({ className }) => {
	return (
		<svg height='100%' width='100%' viewBox='0 0 630 800' className={className}>
			<g
				id='Group_5012'
				data-name='Group 5012'
				transform='translate(-44.912 -309.146)'
				style={{ opacity: '0.03' }}>
				{' '}
				<path
					id='Path_40632'
					data-name='Path 40632'
					style={{ fill: '#fff' }}
					d='M702.206 701.239 398.822 397.853c-71.413-71.414-71.01-187.188.406-258.6 70.99-71.006 186.781-71.4 258.194 0l-.407.407L960.4 443.044z'
					transform='translate(-345.413 -85.85)'
				/>
				<path
					id='Path_40631'
					data-name='Path 40631'
					style={{ fill: '#fff' }}
					d='m398.869 436.328 303.369-303.387 258.212 258.2-303.387 303.381.407.39c-71.415 71.414-187.2 71.007-258.6-.39-71.009-71.007-71.415-186.798-.001-258.194z'
					transform='translate(-345.461 665.297)'
				/>
				<circle
					id='Ellipse_6'
					data-name='Ellipse 6'
					style={{ fill: '#fff' }}
					cx='182.856'
					cy='182.856'
					r='182.856'
					transform='translate(558.329 523.929)'
				/>
			</g>
		</svg>
	);
};

export default Watermarks;