import Logo from 'src/components/atoms/Logo/Logo'

export const navItems = [
  {
    id: 1,
    label: 'About',
    to: '/about',
    subnav: [
      {
        to: '/charitabletrust',
        id: 1,
        label: 'CHARITABLE TRUST',
      },
      {
        to: '/whydifferent',
        id: 2,
        label: 'WHY IS VERIFY DIFFERENT',
      },
    ],
  },
  {
    id: 2,
    label: 'How It Works',
    to: '/howto',
    subnav: [
      {
        to: '/managedata',
        id: 1,
        label: 'MANAGE MY DATA',
      },
    ],
  },
  { id: 3, label: <Logo className='w-32' />, to: '/' },
  { id: 4, label: 'Corporate Solutions', to: '/solutions' },
  { id: 5, label: 'Contact', to: '/contact' },
]

export const navItemsPromo = [
  { id: 1, label: <Logo className='w-32' />, to: '/' },
]
