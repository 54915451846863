import { string, number } from 'prop-types';

const Pills = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 72 72'>
			<path
				d='M21.86,18a9,9,0,0,1,9,9V45a9,9,0,0,1-9,9,9,9,0,0,1-7.8-4.5,8.87,8.87,0,0,1-1.2-4.5V27a9,9,0,0,1,4.5-7.79A8.78,8.78,0,0,1,21.86,18Zm6.43,18V27a6.45,6.45,0,0,0-6.43-6.43A6.45,6.45,0,0,0,15.43,27v9Zm0,9V38.57H15.43V45a6.45,6.45,0,0,0,6.43,6.43A6.45,6.45,0,0,0,28.29,45Zm18-16.71A12.56,12.56,0,0,1,52.71,30a12.87,12.87,0,0,1,4.71,4.7,12.88,12.88,0,0,1,0,12.86,12.79,12.79,0,0,1-4.71,4.7,12.8,12.8,0,0,1-12.85,0,12.77,12.77,0,0,1-4.7-4.7,12.81,12.81,0,0,1,0-12.86,12.84,12.84,0,0,1,4.7-4.7A12.57,12.57,0,0,1,46.29,28.29Zm0,23.14a10,10,0,0,0,6.26-2.17L38.17,34.88A10,10,0,0,0,36,41.14a10.22,10.22,0,0,0,1.37,5.19,10,10,0,0,0,3.73,3.73A10.22,10.22,0,0,0,46.29,51.43Zm8.11-4a10,10,0,0,0,2.17-6.27A10.2,10.2,0,0,0,55.21,36a10.09,10.09,0,0,0-3.74-3.74,10.2,10.2,0,0,0-5.18-1.36A10,10,0,0,0,40,33Z'
				fill={color}
			/>
		</svg>
	);
};
export default Pills;

Pills.defaultProps = {
	color: 'white',
	width: 30,
};

Pills.propTypes = {
	className: string,
	color: string,
	width: number,
};
