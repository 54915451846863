const Headshot = ({ defs, fill, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 81.791 65.433'
      fill={fill || 'currentColor'}
      {...props}
    >
      {defs}
      <path
        id='barcode-read-light'
        d='M19.425 0H1.022A1.025 1.025 0 0 0 0 1.022v19.426a1.025 1.025 0 0 0 1.022 1.022h2.045a1.025 1.025 0 0 0 1.023-1.022V4.09h15.335a1.025 1.025 0 0 0 1.022-1.022V1.022A1.025 1.025 0 0 0 19.425 0zm0 61.343H4.09V44.985a1.025 1.025 0 0 0-1.022-1.022H1.022A1.025 1.025 0 0 0 0 44.985V64.41a1.025 1.025 0 0 0 1.022 1.022h18.4a1.025 1.025 0 0 0 1.022-1.022v-2.045a1.025 1.025 0 0 0-1.019-1.022zM80.768 0h-18.4a1.025 1.025 0 0 0-1.022 1.022v2.045a1.025 1.025 0 0 0 1.019 1.023H77.7v16.358a1.025 1.025 0 0 0 1.022 1.022h2.045a1.025 1.025 0 0 0 1.022-1.022V1.022A1.025 1.025 0 0 0 80.768 0zm0 43.963h-2.044a1.025 1.025 0 0 0-1.024 1.022v16.358H62.365a1.025 1.025 0 0 0-1.022 1.022v2.045a1.025 1.025 0 0 0 1.022 1.022h18.4a1.025 1.025 0 0 0 1.022-1.022V44.985a1.025 1.025 0 0 0-1.019-1.022z'
      />
      <path
        id='head-side-light'
        d='M43.831 23.672c-1.8-4.056-4.17-13.061-6.291-16.075A17.9 17.9 0 0 0 22.9 0h-5.685C8.217 0 .353 6.893.011 15.886a16.467 16.467 0 0 0 5.5 12.935v14.563a.688.688 0 0 0 .689.689h1.374a.688.688 0 0 0 .689-.689V27.592l-.916-.821a13.745 13.745 0 0 1-4.584-10.78c.279-7.3 6.761-13.236 14.453-13.236H22.9a15.156 15.156 0 0 1 12.387 6.426c1.078 1.532 2.506 5.745 3.654 9.13.838 2.471 1.629 4.806 2.373 6.479h-5.507v8.264a2.758 2.758 0 0 1-2.755 2.755h-8.263v7.575a.688.688 0 0 0 .689.689h1.377a.688.688 0 0 0 .689-.689v-4.82h5.509a5.509 5.509 0 0 0 5.509-5.509v-5.51h2.751a2.755 2.755 0 0 0 2.518-3.873zM30.3 16.527a2.755 2.755 0 1 0-2.755 2.755 2.755 2.755 0 0 0 2.755-2.755z'
        transform='translate(19.593 10.129)'
      />
    </svg>
  )
}

export default Headshot
