import React from 'react';
import classnames from 'classnames';
import { string, bool, func, oneOf, node } from 'prop-types';
import { TextHeading, TextBody, TextLink } from '../../atoms/Text/index';
import { TextVariants } from '../../../constants/types';

const Text = ({
	children,
	variant,
	mobile,
	className,
	onClick,
	colorClass,
	fontSize,
}) => {
	switch (variant) {
		case 'header':
			return (
				<TextHeading
					headingVariant='h1'
					className={classnames(
						'font-primary font-bold',
						fontSize ? fontSize : 'mdMin:text-90 mdMax:text-75',
						className
					)}
					onClick={onClick}>
					{children}
				</TextHeading>
			);
		case 'header-2':
			return (
				<TextHeading
					headingVariant='h2'
					className={classnames(
						'font-primary font-bold',
						fontSize ? fontSize : 'mdMin:text-60 mdMax:text-49',
						className
					)}
					onClick={onClick}>
					{children}
				</TextHeading>
			);
		case 'header-3':
			return (
				<TextHeading
					headingVariant='h3'
					className={classnames(
						'font-medium font-secondary',
						fontSize ? fontSize : 'mdMin:text-52 mdMax:text-40',
						className
					)}>
					{children}
				</TextHeading>
			);
		case 'header-4':
			return (
				<TextHeading
					headingVariant='h4'
					className={classnames(
						'font-primary font-medium tracking-tight',
						fontSize ? fontSize : 'mdMin:text-45 mdMax:text-30',
						className
					)}
					onClick={onClick}>
					{children}
				</TextHeading>
			);
		case 'header-pop-up':
			return (
				<TextHeading
					headingVariant='h6'
					className={classnames(
						'font-secondary-regular font-medium',
						fontSize ? fontSize : 'mdMin:text-30 mdMax:text-20',
						className
					)}
					onClick={onClick}>
					{children}
				</TextHeading>
			);
		case 'header-article':
			return (
				<TextHeading
					headingVariant='h6'
					className={classnames(
						'font-primary tracking-wide',
						fontSize ? fontSize : 'mdMin:text-27 mdMax:text-18',
						className
					)}
					onClick={onClick}>
					{children}
				</TextHeading>
			);
		case 'header-footer':
			return (
				<TextHeading
					headingVariant='h6'
					className={classnames(
						'font-secondary-regular font-medium',
						fontSize ? fontSize : 'mdMin:text-21 mdMax:text-16',
						className
					)}
					onClick={onClick}>
					{children}
				</TextHeading>
			);
		case 'header-text-field':
			return (
				<TextHeading
					headingVariant='h6'
					className={classnames(
						'font-primary font-bold',
						fontSize ? fontSize : 'mdMin:text-13 mdMax:text-20',
						className
					)}
					onClick={onClick}>
					{children}
				</TextHeading>
			);
		case 'body':
			return (
				<TextBody
					className={classnames(
						'font-bold font-primary',
						mobile ? 'text-xs' : 'text-sm',
						className
					)}
					onClick={onClick}>
					{children}
				</TextBody>
			);
		case 'body-2':
			return (
				<TextBody
					className={classnames(
						'font-secondary',
						fontSize ? fontSize : 'mdMin:text-27 mdMax:text-18',
						className
					)}
					onClick={onClick}>
					{children}
				</TextBody>
			);
		case 'link':
			return (
				<TextLink
					className={classnames(
						fontSize ? fontSize : 'mdMin:text-24 mdMax:text-17',
						className
					)}
					onClick={onClick}
					colorClass={colorClass}>
					{children}
				</TextLink>
			);
		default:
			return <TextBody className={className}>{children}</TextBody>;
	}
};
export default Text;

Text.propTypes = {
	children: node,
	variant: oneOf(TextVariants),
	mobile: bool,
	className: string,
	onClick: func,
	colorClass: string,
};
