import classnames from 'classnames'
import { string, number } from 'prop-types'

import Ring from 'src/components/atoms/SVG/Ring'

const GradientSpinner = ({ size, className }) => {
  return (
    <Ring
      className={classnames(`w-${size} h-${size}`, 'animate-spin', className)}
      fill='url(#verify-gradient)'
    />
  )
}
export default GradientSpinner

GradientSpinner.defaultProps = {
  size: 36,
}

GradientSpinner.propTypes = {
  size: number,
  className: string,
}
