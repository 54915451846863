const Pin = ({ color }) => {
  return (
    <svg
      width="34"
      height="39"
      viewBox="0 0 40 46"
      className="absolute -bottom-3 z-0"
    >
      <path
        d="M18.9308 45.5692C19.5271 46.1436 20.4729 46.1436 21.0692 45.5692L34.256 32.8663L34.2672 32.8553C37.0273 30.1417 38.9004 26.697 39.6431 22.9506C40.3859 19.2037 39.9633 15.3297 38.4308 11.8167C36.8987 8.30423 34.3279 5.31411 31.0489 3.21518C27.7704 1.11657 23.9269 0 20 0C16.0731 0 12.2296 1.11657 8.95114 3.21518C5.67213 5.31411 3.10132 8.30423 1.56916 11.8167C0.0367165 15.3297 -0.385895 19.2037 0.356888 22.9506C1.0996 26.697 2.97262 30.1417 5.73273 32.8554L18.9308 45.5692Z"
        fill={color}
      />
    </svg>
  );
};

export default Pin;
