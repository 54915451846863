import { string, number } from 'prop-types';

const MoneyEnvelope = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 72 72'>
			<path
				d='M54,29.57V51.43A2.6,2.6,0,0,1,51.43,54H20.57A2.6,2.6,0,0,1,18,51.43V29.57a4.75,4.75,0,0,1,.64-2.41l3.94-7.39a2.37,2.37,0,0,1,.92-1.29A2.57,2.57,0,0,1,25,18H47a2.57,2.57,0,0,1,1.49.48,2.37,2.37,0,0,1,.92,1.29l3.94,7.39A4.75,4.75,0,0,1,54,29.57ZM20.57,51.43H51.43V30.86H20.57ZM25,20.57l-4.1,7.72H34.71V20.57ZM38,40.42a3.11,3.11,0,0,1,1.77,1.25,3.45,3.45,0,0,1,.64,2,3.5,3.5,0,0,1-.88,2.37,3.12,3.12,0,0,1-2.25,1.09v1a.71.71,0,0,1-.65.65H35.36a.71.71,0,0,1-.65-.65v-1a4.11,4.11,0,0,1-2.25-.88.52.52,0,0,1-.24-.45,1,1,0,0,1,.16-.52l1-.88a.64.64,0,0,1,.8-.08,1.7,1.7,0,0,0,.89.24h2.08a.66.66,0,0,0,.53-.24,1,1,0,0,0,.2-.65.75.75,0,0,0-.56-.8l-3.3-1a3.16,3.16,0,0,1-1.77-1.25,3.62,3.62,0,0,1,.24-4.42,3.11,3.11,0,0,1,2.25-1.08v-1a.59.59,0,0,1,.21-.44.62.62,0,0,1,.44-.2h1.28a.62.62,0,0,1,.44.2.59.59,0,0,1,.21.44v1a4,4,0,0,1,2.25.88.5.5,0,0,1,.24.44,1,1,0,0,1-.16.52l-1,.89a.65.65,0,0,1-.8.08,1.7,1.7,0,0,0-.89-.24H34.88a.66.66,0,0,0-.53.24,1,1,0,0,0-.2.64.76.76,0,0,0,.56.81Zm-.72-19.85v7.72H51.11L47,20.57Z'
        fill={color}
			/>
		</svg>
	);
};
export default MoneyEnvelope;

MoneyEnvelope.defaultProps = {
	color: 'white',
	width: 30,
};

MoneyEnvelope.propTypes = {
	className: string,
	color: string,
	width: number,
};
