import { string, number } from 'prop-types';

const Bullhorn = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 104.821 93.176'>
			<defs>
				<linearGradient
					id='a'
					x1='.5'
					x2='.5'
					y2='1'
					gradientUnits='objectBoundingBox'>
					<stop offset='0' stopColor='#efea5a' />
					<stop offset='.5' stopColor='#91e474' />
					<stop offset='1' stopColor='#00da9d' />
				</linearGradient>
			</defs>
			<path
				d='M99 33.645V5.824C99 4.233 97.729 0 93.178 0a5.81 5.81 0 0 0-3.638 1.278l-15.474 12.38a44.17 44.17 0 0 1-27.478 9.636H11.647A11.646 11.646 0 0 0 0 34.941v17.471a11.646 11.646 0 0 0 11.647 11.647l-.087 5.824a46.1 46.1 0 0 0 4.652 20.189 5.711 5.711 0 0 0 5.168 3.1h19.341a5.786 5.786 0 0 0 4.713-9.2 23.175 23.175 0 0 1-4.759-14.093 22.022 22.022 0 0 1 .8-5.824h5.11a44.161 44.161 0 0 1 27.476 9.636l15.474 12.38a5.828 5.828 0 0 0 3.636 1.278c4.535 0 5.823-4.146 5.823-5.824V53.707a11.552 11.552 0 0 0 0-20.062zM40.721 87.353l-19.27-.005a39.27 39.27 0 0 1-4.069-17.378l.087-5.824v-.087h18.078a27.725 27.725 0 0 0-.693 5.824 28.512 28.512 0 0 0 5.867 17.471zM11.647 58.235a5.831 5.831 0 0 1-5.824-5.824v-17.47a5.831 5.831 0 0 1 5.824-5.824h34.941v29.118zm81.538 23.1a.9.9 0 0 1-.046.164L77.7 69.147a50.015 50.015 0 0 0-25.29-10.524V28.73A50.014 50.014 0 0 0 77.7 18.2L93.149 5.847a.027.027 0 0 0 .015.009c.007 0 .011-.009.013-.031l.009 75.509z'
				fill={color || 'url(#a)'}
			/>
		</svg>
	);
};
export default Bullhorn;

Bullhorn.defaultProps = {
	color: undefined,
	width: 30,
};

Bullhorn.propTypes = {
	className: string,
	color: string,
	width: number,
};
