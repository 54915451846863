import { NavLink as Link } from 'react-router-dom'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { combinedStyles } from './styles'
import { navItems, navItemsPromo } from './navItems'
import { SubNav } from './Subnav'
import { promoVersion } from 'src/redux/selectors/promoSelector'

export const NavDesktop = ({ className }) => {
  let promoSearch = useSelector(promoVersion)
  const navLink = promoSearch ? navItemsPromo : navItems
  return (
    <nav
      className={classNames(className, {
        'justify-center': navLink?.length === 1,
        'justify-between': navLink?.length > 1,
      })}
    >
      {navLink.map(({ to, label, subnav }, i) =>
        !!subnav ? (
          <SubNav
            key={`Desktop__Subnav--${i}`}
            defaultChildProps={{
              name: label,
              path: to,
            }}
          >
            {subnav.map(({ to, label }) => (
              <Link to={to} className={combinedStyles.subnavElement}>
                {label}
              </Link>
            ))}
          </SubNav>
        ) : (
          <Link to={to} className={combinedStyles.parentLink}>
            {label}
          </Link>
        )
      )}
    </nav>
  )
}
export default NavDesktop
