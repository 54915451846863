import {
  GET_NAME_SEARCH,
  GET_PHOTO_SEARCH,
  GET_PHONE_SEARCH,
  GET_VECHICLE_SEARCH,
  RESET_SEARCH,
  GET_WEB_SEARCH,
  GET_NAME_WEB_SEARCH,
  ENHANCED_SEARCH,
  GET_ADDRESS_SEARCH,
  GET_CRIMINAL
} from '../actions/types'

const initialState = {
  matches: [],
  enhancedMatches: [],
  idCriminal: null,
  count: null,
  id: null,
  status: null,
  error: null,
}

export default function searchReducer(state = initialState, { type, payload }) {
  switch (type) {
    case RESET_SEARCH: {
      return initialState
    }
    case GET_NAME_SEARCH.TRIGGER: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case GET_NAME_SEARCH.SUCCESS: {
      return {
        error: null,
        matches: payload.data,
        id: payload.id,
        status: 'success',
      }
    }
    case GET_NAME_SEARCH.FAIL: {
      return {
        ...state,
        status: 'error',
        error: payload,
      }
    }
    case GET_PHONE_SEARCH.TRIGGER: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case GET_PHONE_SEARCH.SUCCESS: {
      return {
        error: null,
        matches: payload.data,
        id: payload.id,
        status: 'success',
      }
    }
    case GET_PHONE_SEARCH.FAIL: {
      return {
        ...state,
        status: 'error',
        error: payload,
      }
    }
    case GET_VECHICLE_SEARCH.TRIGGER: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case GET_VECHICLE_SEARCH.SUCCESS: {
      return {
        error: null,
        matches: payload.data,
        id: payload.id,
        status: 'success',
      }
    }
    case GET_VECHICLE_SEARCH.FAIL: {
      return {
        ...state,
        status: 'error',
        error: payload,
      }
    }
    case GET_ADDRESS_SEARCH.TRIGGER: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case GET_ADDRESS_SEARCH.SUCCESS: {
      return {
        error: null,
        matches: payload?.data || [],
        id: payload.id,
        status: 'success',
      }
    }
    case GET_ADDRESS_SEARCH.FAIL: {
      return {
        ...state,
        status: 'error',
        error: payload,
      }
    }
    case GET_PHOTO_SEARCH.TRIGGER: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case GET_PHOTO_SEARCH.SUCCESS: {
      return {
        error: null,
        matches: payload.matches,
        status: 'success',
      }
    }
    case GET_PHOTO_SEARCH.FAIL: {
      return {
        ...state,
        status: 'error',
        error: payload,
      }
    }

    case ENHANCED_SEARCH.TRIGGER: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case ENHANCED_SEARCH.SUCCESS: {
      return {
        error: null,
        enhancedMatches: payload,
        status: 'success',
      }
    }
    case ENHANCED_SEARCH.FAIL: {
      return {
        ...state,
        status: 'error',
        error: payload,
      }
    }

    case GET_WEB_SEARCH.TRIGGER: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case GET_WEB_SEARCH.SUCCESS: {
      return {
        error: null,
        matches: payload?.data || [],
        count: payload.count,
        id: payload.id,
        status: 'success',
      }
    }
    case GET_WEB_SEARCH.FAIL: {
      return {
        ...state,
        status: 'error',
        error: payload,
      }
    }
    case GET_NAME_WEB_SEARCH.TRIGGER: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case GET_NAME_WEB_SEARCH.SUCCESS: {
      return {
        error: null,
        matches: payload.data,
        id: payload.id,
        status: 'success',
      }
    }
    case GET_NAME_WEB_SEARCH.FAIL: {
      return {
        ...state,
        status: 'error',
        error: payload,
      }
    }
    case GET_CRIMINAL.TRIGGER: {
      return {
        ...state,
        status: 'loading',
      }
    }
    case GET_CRIMINAL.SUCCESS: {
      return {
        ...state,
        idCriminal: payload,
        status: 'success',
      }
    }
    case GET_CRIMINAL.FAIL: {
      return {
        ...state,
        status: 'error',
        error: payload,
      }
    }
    default:
      return state
  }
}
