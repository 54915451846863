import { string, number } from 'prop-types';

const ProfileIcon = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 72 72'>
			<path
				d='M36,16.2a19.2,19.2,0,0,1,9.9,2.67,20.2,20.2,0,0,1,7.23,7.23,19.69,19.69,0,0,1,0,19.8,20.2,20.2,0,0,1-7.23,7.23,19.69,19.69,0,0,1-19.8,0,20.2,20.2,0,0,1-7.23-7.23,19.69,19.69,0,0,1,0-19.8,20.2,20.2,0,0,1,7.23-7.23A19.2,19.2,0,0,1,36,16.2ZM48.69,47.66a17.24,17.24,0,0,0,2.24-20.32,17.14,17.14,0,0,0-6.27-6.27,17.32,17.32,0,0,0-17.32,0,17.14,17.14,0,0,0-6.27,6.27A16.85,16.85,0,0,0,18.75,36a17.18,17.18,0,0,0,4.56,11.66A7.76,7.76,0,0,1,25.9,43a7.28,7.28,0,0,1,5-1.88,6.35,6.35,0,0,1,1.92.4,10.84,10.84,0,0,0,6.38,0,6.35,6.35,0,0,1,1.92-.4,7.28,7.28,0,0,1,5,1.88A7.76,7.76,0,0,1,48.69,47.66Zm-2.47,2.23V48.77a5.19,5.19,0,0,0-5.11-5.11,5.71,5.71,0,0,0-1.2.24,12.26,12.26,0,0,1-7.82,0,5.71,5.71,0,0,0-1.2-.24,5.19,5.19,0,0,0-5.11,5.11v1.12a17.22,17.22,0,0,0,20.44,0ZM36,25.14a7.1,7.1,0,0,1,7,7,7,7,0,0,1-12,4.95,6.93,6.93,0,0,1,0-9.9A6.73,6.73,0,0,1,36,25.14Zm0,11.5A4.46,4.46,0,0,0,39.15,29a4.46,4.46,0,1,0-6.3,6.31A4.28,4.28,0,0,0,36,36.64Z'
				fill={color}
			/>
		</svg>
	);
};

export default ProfileIcon;

ProfileIcon.defaultProps = {
	color: 'white',
	width: 30,
};

ProfileIcon.propTypes = {
	className: string,
	color: string,
	width: number,
};
