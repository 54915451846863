const Camera = ({ fill, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 72.728 55.282'
      fill={fill || 'currentColor'}
      {...props}
    >
      <g
        id='Group_31747'
        data-name='Group 31747'
        transform='translate(-1214.606 -156.469)'
      >
        <g
          id='Group_31745'
          data-name='Group 31745'
          transform='translate(1235.794 173.573)'
        >
          <path
            id='Path_297046'
            data-name='Path 297046'
            d='M1279.365 226.847a15.176 15.176 0 1 1 15.176-15.176 15.193 15.193 0 0 1-15.176 15.176zm0-26.078a10.9 10.9 0 1 0 10.9 10.9 10.915 10.915 0 0 0-10.9-10.9z'
            transform='translate(-1264.19 -196.496)'
          />
        </g>
        <g
          id='Group_31746'
          data-name='Group 31746'
          transform='translate(1268.49 171.634)'
        >
          <path
            id='Path_297047'
            data-name='Path 297047'
            d='M1347 204.561a6.3 6.3 0 1 1 6.3-6.3 6.308 6.308 0 0 1-6.3 6.3zm0-9.184a2.883 2.883 0 1 0 2.883 2.883 2.886 2.886 0 0 0-2.883-2.883z'
            transform='translate(-1340.703 -191.958)'
          />
        </g>
        <path
          id='Path_297048'
          data-name='Path 297048'
          d='M1285.2 165.747h-37.1l-8.652-8.652a2.138 2.138 0 0 0-1.511-.626h-11.917a2.137 2.137 0 0 0-1.51.626l-9.278 9.278a2.136 2.136 0 0 0-.626 1.511v41.731a2.137 2.137 0 0 0 2.136 2.137h68.458a2.136 2.136 0 0 0 2.137-2.137v-41.732a2.136 2.136 0 0 0-2.137-2.136zm-58.292-5h10.141l5 5H1221.9zm56.155 46.736h-64.182V170.02h64.182z'
        />
      </g>
    </svg>
  )
}

export default Camera
