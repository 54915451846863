import { string, number } from 'prop-types';

const XIcon = ({ className, color, width, onClick }) => {
	return (
		<svg
			className={className}
			width={width}
			viewBox='0 0 72 72'
			onClick={() => {
				if (onClick) {
					onClick();
				}
			}}>
			<path
				d='M39.06,36,50.22,47.16a.68.68,0,0,1,.18.5.66.66,0,0,1-.18.49l-2.07,2.07a.66.66,0,0,1-.49.18.68.68,0,0,1-.5-.18L36,39.06,24.84,50.22a.68.68,0,0,1-.5.18.66.66,0,0,1-.49-.18l-2.07-2.07a.66.66,0,0,1-.18-.49.68.68,0,0,1,.18-.5L32.94,36,21.78,24.84a.68.68,0,0,1-.18-.5.66.66,0,0,1,.18-.49l2.07-2.07a.66.66,0,0,1,.49-.18.68.68,0,0,1,.5.18L36,32.94,47.16,21.78a.68.68,0,0,1,.5-.18.66.66,0,0,1,.49.18l2.07,2.07a.66.66,0,0,1,.18.49.68.68,0,0,1-.18.5l-1.89,1.89Z'
				fill={color}
			/>
		</svg>
	);
};
export default XIcon;

XIcon.defaultProps = {
	color: 'white',
	width: 25,
};

XIcon.propTypes = {
	className: string,
	color: string,
	width: number,
};
