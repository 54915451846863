const Film = ({ fill, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 78.382 58.786'
      fill={fill || 'currentColor'}
      {...props}
    >
      <path
        d='M74.707 64h-1.224v3.062a1.842 1.842 0 0 1-1.837 1.838h-6.124a1.842 1.842 0 0 1-1.837-1.837V64H14.7v3.062a1.842 1.842 0 0 1-1.841 1.838H6.736A1.842 1.842 0 0 1 4.9 67.062V64H3.674A3.665 3.665 0 0 0 0 67.674v51.438a3.665 3.665 0 0 0 3.674 3.674H4.9v-3.062a1.842 1.842 0 0 1 1.837-1.837h6.124a1.842 1.842 0 0 1 1.837 1.837v3.062h48.987v-3.062a1.842 1.842 0 0 1 1.837-1.837h6.124a1.842 1.842 0 0 1 1.837 1.837v3.062h1.225a3.665 3.665 0 0 0 3.674-3.674V67.674A3.665 3.665 0 0 0 74.707 64zM14.7 111.151a1.842 1.842 0 0 1-1.837 1.837H6.736a1.842 1.842 0 0 1-1.836-1.837v-6.124a1.842 1.842 0 0 1 1.837-1.837h6.124a1.842 1.842 0 0 1 1.837 1.837zm0-14.7a1.842 1.842 0 0 1-1.837 1.837H6.736A1.842 1.842 0 0 1 4.9 96.455v-6.124a1.842 1.842 0 0 1 1.837-1.837h6.124a1.842 1.842 0 0 1 1.839 1.837zm0-14.7a1.842 1.842 0 0 1-1.841 1.849H6.736A1.842 1.842 0 0 1 4.9 81.758v-6.123A1.842 1.842 0 0 1 6.736 73.8h6.124a1.842 1.842 0 0 1 1.84 1.835zm44.09 34.292a1.842 1.842 0 0 1-1.837 1.837H21.432a1.842 1.842 0 0 1-1.832-1.83V97.68a1.842 1.842 0 0 1 1.837-1.837h35.512a1.842 1.842 0 0 1 1.837 1.837zm0-26.944a1.842 1.842 0 0 1-1.837 1.837H21.432a1.842 1.842 0 0 1-1.832-1.829V70.736a1.842 1.842 0 0 1 1.832-1.836h35.517a1.842 1.842 0 0 1 1.837 1.837zm14.7 22.045a1.842 1.842 0 0 1-1.837 1.837h-6.131a1.842 1.842 0 0 1-1.837-1.837v-6.124a1.842 1.842 0 0 1 1.837-1.837h6.124a1.842 1.842 0 0 1 1.837 1.837zm0-14.7a1.842 1.842 0 0 1-1.837 1.837h-6.131a1.842 1.842 0 0 1-1.837-1.837v-6.113a1.842 1.842 0 0 1 1.837-1.837h6.124a1.842 1.842 0 0 1 1.837 1.837zm0-14.7a1.842 1.842 0 0 1-1.844 1.856h-6.124a1.842 1.842 0 0 1-1.837-1.837v-6.128a1.842 1.842 0 0 1 1.837-1.835h6.124a1.842 1.842 0 0 1 1.837 1.837z'
        transform='translate(0 -64)'
      />
    </svg>
  )
}

export default Film
