import { string, number } from 'prop-types';

const MagnifyingGlass = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 21.401 38.047'>
			<defs>
				<linearGradient
					id='a'
					x1='.5'
					x2='.5'
					y2='1'
					gradientUnits='objectBoundingBox'>
					<stop offset='0' stopColor='#efea5a' />
					<stop offset='.5' stopColor='#91e474' />
					<stop offset='1' stopColor='#00da9d' />
				</linearGradient>
			</defs>
			<path
				d='M10.7 0a10.673 10.673 0 0 0-1.188 21.282v15.34l.818 1.227a.446.446 0 0 0 .742 0l.818-1.227v-15.34A10.673 10.673 0 0 0 10.7 0zm0 19.023a8.323 8.323 0 1 1 8.323-8.323 8.332 8.332 0 0 1-8.323 8.323zm0-14.268A5.951 5.951 0 0 0 4.756 10.7a1.189 1.189 0 1 0 2.378 0A3.571 3.571 0 0 1 10.7 7.134a1.189 1.189 0 0 0 0-2.378z'
				fill={color || 'url(#a)'}
			/>
		</svg>
	);
};
export default MagnifyingGlass;

MagnifyingGlass.defaultProps = {
	color: undefined,
	width: 30,
};

MagnifyingGlass.propTypes = {
	className: string,
	color: string,
	width: number,
};
