const Step = ({ color }) => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16">
      <path
        d="M11.6571 0H3.34291C1.78735 0 0.500001 0.657534 0.500001 1.45205V14.5479C0.500001 15.3425 1.78735 16 3.34291 16H11.6571C13.2126 16 14.5 15.3425 14.5 14.5479V1.45205C14.4464 0.657534 13.2126 0 11.6571 0Z"
        fill={color}
      />
    </svg>
  );
};

export default Step;
