import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Text from "src/components/atoms/Text/Text";

import styles from "./ButtonCta.module.css";

const classes = {
  button:
    "w-full flex rounded py-3 md:py-3 px-8 transition-all duration-150 text-white text-sm font-body items-center justify-center bg-no-repeat",
  icon: "flex items-center mr-2 w-5 h-5 md: mr-4 md:w-6 md:h-6",
  text: "text-center whitespace-nowrap",
};

const colors = {
  white: "text-white-primary",
  black: "text-black-primary",
  grey: "text-grey-dark",
};

const hoverColors = {
  white: `hover:${colors.white}`,
  black: `hover:${colors.black}`,
};

export const Button = ({
  className,
  buttonClass,
  icon,
  text,
  children,
  onClick,
  variant,
  color,
  hoverColor,
  type,
  disabled,
}) => {
  const Icon = icon;
  return (
    <div className={classnames("relative", className)}>
      <div
        className={classnames(
          "absolute w-full h-full top-0 left-0 rounded",
          !disabled && styles.border
        )}
      />
      <button
        className={classnames(
          classes.button,
          colors[color],
          hoverColors[hoverColor || color],
          styles.button,
          styles[variant],
          "border-0 border-none",
          buttonClass
        )}
        onClick={onClick}
        type={type}
        disabled={disabled}
      >
        {icon && <Icon className={classes.icon} />}

        <Text className={classes.text} variant="cta">
          {text || children}
        </Text>
      </button>
    </div>
  );
};

Button.defaultProps = {
  variant: "gradient",
  color: "white",
  hoverColor: "black",
  type: "submit",
  disabled: false,
};

Button.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.elementType,
  text: PropTypes.string,
  children: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["gradient", "transparent", "grey"]),
  color: PropTypes.oneOf(Object.keys(colors)),
  hoverColor: PropTypes.oneOf(Object.keys(hoverColors)),
  disabled: PropTypes.bool,
};

export default Button;
