import classnames from 'classnames'
import { string, node } from 'prop-types'

const SiteContainer = ({ children, className }) => {
  return (
    <div className={classnames('grid auto-rows-auto min-h-page', className)}>
      {children}
    </div>
  )
}

export default SiteContainer

SiteContainer.propTypes = {
  children: node,
  className: string,
}
