const Camcorder = ({ fill, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 88.541 50.626'
      fill={fill || 'currentColor'}
      {...props}
    >
      <path
        data-name='Path 297049'
        d='M1175.883 417.433a2.281 2.281 0 0 0-2.489.5l-16.935 16.934v-13.88a2.284 2.284 0 0 0-2.284-2.284h-63.138a2.284 2.284 0 0 0-2.284 2.284V465.6a2.284 2.284 0 0 0 2.284 2.284h63.138a2.284 2.284 0 0 0 2.284-2.284v-15.32l16.935 16.935a2.284 2.284 0 0 0 3.9-1.615v-46.057a2.283 2.283 0 0 0-1.411-2.11zm-23.992 45.883h-58.57v-40.045h58.57zm20.834-3.23-16.029-16.03-.176-2.794 16.205-16.205z'
        transform='translate(-1088.753 -417.258)'
      />
    </svg>
  )
}

export default Camcorder
