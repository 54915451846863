import { string, number } from 'prop-types';

const Tag = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 72 72'>
			<path
				d='M33.61,19,48.52,33.89a3.4,3.4,0,0,1,0,4.78L34.17,53a3.4,3.4,0,0,1-4.78,0L14.48,38.11a3.28,3.28,0,0,1-1-2.39V21.38A3.35,3.35,0,0,1,16.88,18H31.22A3.28,3.28,0,0,1,33.61,19ZM16.88,20.25a1.09,1.09,0,0,0-1.13,1.13V35.72a.94.94,0,0,0,.35.77L31,51.4a.94.94,0,0,0,.77.35.93.93,0,0,0,.77-.35L46.9,37.05a.93.93,0,0,0,.35-.77.94.94,0,0,0-.35-.77L32,20.6a.94.94,0,0,0-.77-.35ZM26,25.73a3.42,3.42,0,1,1-2.4-1A3.29,3.29,0,0,1,26,25.73Zm-3.38,1.41a1.37,1.37,0,1,0,1-.42A1.35,1.35,0,0,0,22.64,27.14ZM43.17,53a3.4,3.4,0,0,1-4.78,0l-.14-.14,1.62-1.55.14.07a.94.94,0,0,0,.77.35.93.93,0,0,0,.77-.35L55.9,37.05a.93.93,0,0,0,.35-.77.94.94,0,0,0-.35-.77L41,20.6a.94.94,0,0,0-.77-.35H38.88L36.63,18h3.59a3.28,3.28,0,0,1,2.39,1L57.52,33.89a3.4,3.4,0,0,1,0,4.78Z'
				fill={color}
			/>
		</svg>
	);
};
export default Tag;

Tag.defaultProps = {
	color: 'white',
	width: 30,
};

Tag.propTypes = {
	className: string,
	color: string,
	width: number,
};
