import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Text, { TextVariants } from 'src/components/atoms/Text/Text'
import Logo from 'src/components/atoms/Logo/Logo'
import { promoVersion } from 'src/redux/selectors/promoSelector'
const styles = {
  wrapper: 'flex flex-col items-center bg-e5e5e5 pt-20 pb-12 sm:pt-14 sm:pb-8',
  container:
    'w-11/12 flex flex-col gap-y-8 gap-x-8 items-center justify-between text-787878 sm:flex-row sm:items-start',
  logo: 'w-36 sm:w-32',
  content: 'flex flex-col items-center gap-y-8 sm:w-4/5 sm:items-start',
  links:
    'grid justify-items-center gap-y-4 sm:justify-items-start sm:grid-cols-2 sm:grid-rows-auto sm:mt-3',
  link: classnames(TextVariants.b1, 'uppercase'),
}

const links = [
  { label: 'FAQ', to: '/faq' },
  { label: 'News & Press', to: '/news' },
  { label: 'Contact Us', to: '/contact' },
  { label: 'Terms of Use', to: '/terms' },
  { label: 'Privacy Policy', to: '/privacy' },
  { label: 'Developer Integration', to: '/developer' },
]

const Footer = () => {
  let promoSearch = useSelector(promoVersion)
  return (
    <footer className={styles.wrapper}>
      <div className={styles.container}>
        <Logo className={styles.logo} />

        <div className={styles.content}>
          {!promoSearch && (
            <div className={styles.links}>
              {links.map(({ label, to }, i) => (
                <Link key={i} className={styles.link} to={to}>
                  {label}
                </Link>
              ))}
            </div>
          )}
          <Text
            variant='b1'
            className={classnames({
              'mt-4': promoSearch,
            })}
          >
            2022 Verify. All Rights Reserved.
          </Text>
          <Text variant='b2'>
            If you are using information for employment or background checks
            that require FCRA approval, you must check the sources, which are
            displayed against the data provided to see whether they are FCRA
            compliant.
          </Text>
        </div>
      </div>
    </footer>
  )
}
export default Footer
