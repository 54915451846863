import { string, number } from 'prop-types';

const ArrowUp = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 72 72'>
			<path
				d='M50.06,41.23a1.21,1.21,0,0,1,.34.9,1.6,1.6,0,0,1-.34,1l-.9.79a1.06,1.06,0,0,1-.9.45,1.4,1.4,0,0,1-1-.45L36,32.46,24.75,43.93a1.62,1.62,0,0,1-1,.34,1.18,1.18,0,0,1-.9-.34l-.79-.79a1.39,1.39,0,0,1-.45-1,1.21,1.21,0,0,1,.34-.9L35.1,28a1.18,1.18,0,0,1,.9-.34A1.6,1.6,0,0,1,37,28Z'
				fill={color}
			/>
		</svg>
	);
};
export default ArrowUp;

ArrowUp.defaultProps = {
	color: 'white',
	width: 25,
};

ArrowUp.propTypes = {
	className: string,
	color: string,
	width: number,
};
