import { useState, useEffect } from 'react'

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const resize = window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth)
    })

    return () => window.removeEventListener('resize', resize)
  })

  return [windowWidth, setWindowWidth]
}
