import { string, number, func } from 'prop-types';

const PlayCircle = ({ className, color, width, onClick }) => {
	return (
		<svg
			className={className}
			width={width}
			viewBox='0 0 48.978 48.978'
			onClick={() => {
				if (onClick) onClick();
			}}>
			<defs>
				<linearGradient
					id='a'
					x1='.5'
					x2='.5'
					y2='1'
					gradientUnits='objectBoundingBox'>
					<stop offset='0' stopColor='#efea5a' />
					<stop offset='.5' stopColor='#91e474' />
					<stop offset='1' stopColor='#00da9d' />
				</linearGradient>
			</defs>
			<path
				d='M43.914 30.712 26.535 20.146a2.374 2.374 0 0 0-3.525 2.074v20.539a2.376 2.376 0 0 0 3.525 2.074l17.379-9.973a2.378 2.378 0 0 0 0-4.148zm13.064 1.777a24.489 24.489 0 1 0-24.489 24.489 24.485 24.485 0 0 0 24.489-24.489zm-44.239 0a19.749 19.749 0 1 1 19.75 19.75 19.744 19.744 0 0 1-19.749-19.75z'
				transform='translate(-8 -8)'
				fill={color || 'url(#a)'}
			/>
		</svg>
	);
};
export default PlayCircle;

PlayCircle.defaultProps = {
	color: undefined,
	width: 30,
};

PlayCircle.propTypes = {
	className: string,
	color: string,
	width: number,
	onClick: func,
};
