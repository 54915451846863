const Picture = ({ fill, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 67.764 50.823'
      fill={fill || 'currentColor'}
      {...props}
    >
      <path
        d='M61.411 64H6.353A6.353 6.353 0 0 0 0 70.353v38.117a6.353 6.353 0 0 0 6.353 6.353h55.058a6.353 6.353 0 0 0 6.353-6.353V70.353A6.353 6.353 0 0 0 61.411 64zm2.118 44.47a2.12 2.12 0 0 1-2.118 2.118H6.353a2.12 2.12 0 0 1-2.118-2.118V70.353a2.12 2.12 0 0 1 2.118-2.118h55.058a2.12 2.12 0 0 1 2.118 2.118zM14.823 86.235a7.412 7.412 0 1 0-7.412-7.412 7.412 7.412 0 0 0 7.412 7.412zm0-10.588a3.176 3.176 0 1 1-3.176 3.176 3.18 3.18 0 0 1 3.176-3.176zm27.4 3.048L29.647 91.272l-4.107-4.106a3.176 3.176 0 0 0-4.492 0L9.4 98.813a3.176 3.176 0 0 0-.93 2.246v3.706a1.588 1.588 0 0 0 1.588 1.588h47.647a1.588 1.588 0 0 0 1.588-1.588V92.588a3.177 3.177 0 0 0-.93-2.246L46.716 78.695a3.177 3.177 0 0 0-4.492 0zm12.834 23.422H12.706v-.62l10.588-10.588 6.353 6.353L44.47 82.438l10.588 10.589z'
        transform='translate(0 -64)'
      />
    </svg>
  )
}

export default Picture
