const Logo = ({ className, fill = 'url(#logo-gradient)', ...props }) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 246 85.197'
      fill={fill || 'currentColor'}
      {...props}
    >
      <g id='Group_31859' transform='translate(-27.318 1538.698)'>
        <g id='Group_4510' transform='translate(27.318 -1538.698)'>
          <path
            d='m361.2-1484.017 13.338-25.009 15.825-29.672h-20.921L334-1471.882h20.722z'
            transform='translate(-313.998 1538.698)'
            fill={fill || 'currentColor'}
          />
          <path
            d='m57.641-1074.349-9.6-18.266H27.318l20 37.721'
            transform='translate(-27.318 1121.71)'
            fill='#10312b'
          />
        </g>
        <g id='Group_4511' transform='translate(105.46 -1524.307)'>
          <path
            fill='#231f20'
            d='M1243.475-1014.222h-2.445l-15.643-34.48h5.337l11.568 27.362 11.791-27.362h5.186z'
            transform='translate(-1225.387 1066.271)'
          />
          <path
            fill='#231f20'
            d='M1755.85-1048.829v.224a13.113 13.113 0 0 0 13.119 13.2 14.432 14.432 0 0 0 10.976-4.969l3.411 4a20.01 20.01 0 0 1-14.387 5.935 18.038 18.038 0 0 1-18.089-18.167 17.96 17.96 0 0 1 18.089-18.239 17.682 17.682 0 0 1 12.015 4.45c2.523 2.373 5.935 7.486 5.935 11.049a2.672 2.672 0 0 1-2.82 2.517zm25.429-4.522c-1.479-4.818-6.744-8.525-12.31-8.525-5.41 0-10.825 3.707-12.232 8.525z'
            transform='translate(-1716.606 1083.23)'
          />
          <path
            fill='#231f20'
            d='M2396.233-1059.431a7.9 7.9 0 0 0-5.488-2.445 9.333 9.333 0 0 0-9.418 9.413v21.282h-4.891v-34.479h4.891v4.824c1.261-3.562 5.416-6.008 9.418-6.008a12.49 12.49 0 0 1 8.748 3.26z'
            transform='translate(-2301.362 1083.23)'
          />
          <path
            fill='#231f20'
            d='M2792.453-1261.324a3.336 3.336 0 0 1-3.188 3.49 3.179 3.179 0 0 1-3.411-3.49 3.21 3.21 0 0 1 3.411-3.333 3.4 3.4 0 0 1 3.188 3.333zm-.895 45.232h-5.041v-34.479h5.041z'
            transform='translate(-2684.075 1268.141)'
          />
          <path
            fill='#231f20'
            d='M2948.791-1310.949a3.676 3.676 0 0 0-3.556-2.524c-2.523 0-3.785 1.485-3.785 3.93v9.044h7.565v4.378h-7.565v30.1h-5.114v-30.1h-6.08v-4.378h6.08v-9.044c0-4.817 3.41-8.525 8.9-8.525a7.376 7.376 0 0 1 7.191 4.443z'
            transform='translate(-2819.059 1318.067)'
          />
          <path
            fill='#231f20'
            d='M3278.5-995.464h-5.336l9.715-21.651-14.756-31.587h5.337l12.31 26.4 11.64-26.4h5.338z'
            transform='translate(-3134.886 1066.271)'
          />
        </g>
      </g>
    </svg>
  )
}
export default Logo
