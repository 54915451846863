import { string, number } from 'prop-types';

const CornersBorder = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 211.52 169.216'>
			<defs>
				<linearGradient
					id='a'
					x1='.5'
					x2='.5'
					y2='1'
					gradientUnits='objectBoundingBox'>
					<stop offset='0' stopColor='#efea5a' />
					<stop offset='.5' stopColor='#91e474' />
					<stop offset='1' stopColor='#00da9d' />
				</linearGradient>
			</defs>
			<path
				d='M50.236 0H2.644A2.652 2.652 0 0 0 0 2.644V52.88a2.652 2.652 0 0 0 2.644 2.644h5.288a2.652 2.652 0 0 0 2.644-2.644v-42.3h39.66a2.652 2.652 0 0 0 2.644-2.648V2.644A2.652 2.652 0 0 0 50.236 0zm0 158.64h-39.66v-42.3a2.652 2.652 0 0 0-2.644-2.644H2.644A2.652 2.652 0 0 0 0 116.336v50.236a2.652 2.652 0 0 0 2.644 2.644h47.592a2.652 2.652 0 0 0 2.644-2.644v-5.288a2.652 2.652 0 0 0-2.644-2.644zM208.876 0h-47.592a2.652 2.652 0 0 0-2.644 2.644v5.288a2.652 2.652 0 0 0 2.644 2.644h39.66v42.3a2.652 2.652 0 0 0 2.644 2.644h5.288a2.652 2.652 0 0 0 2.644-2.644V2.644A2.652 2.652 0 0 0 208.876 0zm0 113.692h-5.288a2.652 2.652 0 0 0-2.644 2.644v42.3h-39.66a2.652 2.652 0 0 0-2.644 2.644v5.288a2.652 2.652 0 0 0 2.644 2.644h47.592a2.652 2.652 0 0 0 2.644-2.644v-50.232a2.652 2.652 0 0 0-2.644-2.644z'
				transform='translate(.001)'
				fill={color || 'url(#a)'}
			/>
		</svg>
	);
};
export default CornersBorder;

CornersBorder.defaultProps = {
	color: undefined,
	width: 30,
};

CornersBorder.propTypes = {
	className: string,
	color: string,
	width: number,
};
