const Geo = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99967 8.66142C6.99967 7.55898 7.89545 6.66317 8.99967 6.66317C10.1039 6.66317 10.9997 7.55898 10.9997 8.66142C10.9997 9.76387 10.1039 10.6597 8.99967 10.6597C7.89546 10.6597 6.99967 9.76387 6.99967 8.66142Z"
        fill="#00DA9D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.333008 8.66142C0.333008 3.87836 4.21456 0 8.99967 0C13.7848 0 17.6663 3.87836 17.6663 8.66142C17.6663 12.2268 15.882 15.0514 13.9709 16.9612C13.0132 17.9183 12.01 18.6604 11.1507 19.1668C10.7212 19.4199 10.3199 19.6188 9.971 19.7565C9.64159 19.8865 9.29582 19.9878 8.99967 19.9878C8.70353 19.9878 8.35776 19.8865 8.02835 19.7565C7.67945 19.6188 7.27811 19.4199 6.84868 19.1668C5.98937 18.6604 4.98613 17.9183 4.02842 16.9612C2.11739 15.0514 0.333008 12.2268 0.333008 8.66142ZM8.99967 5.32983C7.1599 5.32983 5.66634 6.82177 5.66634 8.66142C5.66634 10.501 7.15988 11.993 8.99967 11.993C10.8395 11.993 12.333 10.501 12.333 8.66142C12.333 6.82177 10.8394 5.32983 8.99967 5.32983Z"
        fill="#00DA9D"
      />
    </svg>
  );
};

export default Geo;
