const BirthdayCake = ({ defs, fill, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 59.761 68.298'
      fill={fill || 'currentColor'}
      {...props}
    >
      {defs}
      <path d='M12.806 12.806a4.256 4.256 0 0 1-4.269-4.269c0-4.137 4.269-3.068 4.269-8.537 1.6 0 4.269 3.935 4.269 7.47s-1.901 5.336-4.269 5.336zm17.074 0a4.256 4.256 0 0 1-4.269-4.269C25.612 4.4 29.88 5.469 29.88 0c1.6 0 4.269 3.935 4.269 7.47s-1.901 5.336-4.269 5.336zm17.074 0a4.256 4.256 0 0 1-4.269-4.269c0-4.135 4.269-3.068 4.269-8.537 1.6 0 4.269 3.935 4.269 7.47s-1.9 5.336-4.268 5.336zm6.4 21.343h-4.265V14.94H44.82v19.209H32.015V14.94h-4.269v19.209H14.94V14.94h-4.268v19.209H6.4a6.4 6.4 0 0 0-6.4 6.4V68.3h59.761V40.552a6.4 6.4 0 0 0-6.403-6.403zm2.134 29.88H4.269v-9.61c2.164-1.264 3.192-3.2 5.7-3.2 3.729 0 4.169 4.269 9.971 4.269 5.715 0 6.3-4.269 9.938-4.269 3.755 0 4.162 4.269 9.971 4.269 5.784 0 6.238-4.269 9.971-4.269 2.466 0 3.5 1.931 5.669 3.2zm0-14.99a8.136 8.136 0 0 0-5.669-2.084c-5.793 0-6.246 4.269-9.971 4.269-3.694 0-4.2-4.269-9.971-4.269-5.715 0-6.3 4.269-9.938 4.269-3.755 0-4.162-4.269-9.971-4.269a8.182 8.182 0 0 0-5.7 2.089v-8.492A2.137 2.137 0 0 1 6.4 38.418h46.958a2.137 2.137 0 0 1 2.134 2.134z' />
    </svg>
  )
}

export default BirthdayCake
