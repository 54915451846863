const ArrowLeft = ({ ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 17.142 34.283'
    fill='currentColor'
    {...props}
  >
    <path
      d='m0 0 17.142 17.142L0 34.283z'
      transform='rotate(180 8.571 17.142)'
    />
  </svg>
)

export default ArrowLeft
