import React from 'react';
import { string, number } from 'prop-types';

const Upload = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 72 72'>
			<path
				d='M55.8,52.4a3.45,3.45,0,0,1-3.4,3.4H19.6a3.45,3.45,0,0,1-3.4-3.4v-8A3.45,3.45,0,0,1,19.6,41h9.59V33.29h-4.1a2.81,2.81,0,0,1-2.16-.93A3.25,3.25,0,0,1,22,30.2,3,3,0,0,1,22.93,28l10.9-10.83a3,3,0,0,1,4.34,0L49.07,28A3,3,0,0,1,50,30.2a3.25,3.25,0,0,1-.89,2.16,2.81,2.81,0,0,1-2.16.93h-4.1V41H52.4a3.45,3.45,0,0,1,3.4,3.4Zm-2.75-8.7a.9.9,0,0,0-.65-.28H42.81v1.86a3,3,0,0,1-3.1,3.1H32.29a3,3,0,0,1-3.1-3.1V43.42H19.6a.9.9,0,0,0-.65.28.89.89,0,0,0-.27.65v8a.89.89,0,0,0,.27.65.9.9,0,0,0,.65.28H52.4a1,1,0,0,0,.93-.93v-8A.9.9,0,0,0,53.05,43.7ZM31.67,45.28a.65.65,0,0,0,.62.62h7.42a.65.65,0,0,0,.62-.62V30.82h6.58a.56.56,0,0,0,.54-.39.59.59,0,0,0-.16-.69L36.46,18.83a.74.74,0,0,0-.92,0L24.71,29.74a.59.59,0,0,0-.16.69.56.56,0,0,0,.54.39h6.58Zm11.91,3.25a1.48,1.48,0,0,0-.46,1.08,1.5,1.5,0,0,0,.46,1.09,1.51,1.51,0,0,0,2.17,0,1.54,1.54,0,0,0,.46-1.09,1.52,1.52,0,0,0-.46-1.08,1.51,1.51,0,0,0-2.17,0ZM50.7,50.7a1.54,1.54,0,0,1-1.09.46,1.52,1.52,0,0,1-1.08-.46,1.5,1.5,0,0,1-.46-1.09,1.57,1.57,0,0,1,1.54-1.54,1.5,1.5,0,0,1,1.09.46,1.52,1.52,0,0,1,.46,1.08A1.54,1.54,0,0,1,50.7,50.7Z'
				fill={color}
			/>
		</svg>
	);
};
export default Upload;

Upload.defaultProps = {
	color: 'white',
	width: 30,
};

Upload.propTypes = {
	className: string,
	color: string,
	width: number,
};
