import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "src/redux/store";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SiteContainer from "./layout/Container/SiteContainer";
import Routing from "src/routing/routes";
import { ModalProvider } from "src/components/contexts/ModalContext";
import { GradientDefs, ScrollToTop } from "src/components/utility";
import Navbar from "../components/organisms/NavBar/NavBar";
import Footer from "../components/organisms/Footer/Footer";
require("dotenv").config()
const stripePromise = loadStripe(`${process.env.REACT_APP_PUBLISH_KEY}`);

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc:
        "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Poppins:wght@400;500;700&display=swap",
    },
  ],
};

const App = () => {

  return (
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <ScrollToTop />
          <GradientDefs />
          <ModalProvider>
            <Navbar />
            <SiteContainer>
              <Routing />
            </SiteContainer>
            <Footer />
          </ModalProvider>
        </BrowserRouter>
      </Elements>
    </Provider>
  );
};

export default App;
