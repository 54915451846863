import React, { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import Loading from 'src/components/organisms/Loading/Loading'
import StyleGuide from 'src/components/pages/StyleGuide/StyleGuide'

const Checkout = lazy(() =>
import('src/components/pages/Checkout/Checkout')
)

const CharitableTrust = lazy(() =>
  import('src/components/pages/CharitableTrust/CharitableTrust')
)
const RemoveInfo = lazy(() =>
  import('src/components/pages/RemoveInfo/RemoveInfo')
)
const CorporateSolutions = lazy(() =>
  import('src/components/pages/CorporateSolutions/CorporateSolutions')
)
const HowTo = lazy(() => import('src/components/pages/HowTo/HowTo'))
const News = lazy(() => import('src/components/pages/News/News'))
const Privacy = lazy(() => import('src/components/pages/Privacy/Privacy'))
const Terms = lazy(() => import('src/components/pages/Terms/Terms'))
const Faq = lazy(() => import('src/components/pages/FAQ/Faq'))
const About = lazy(() => import('src/components/pages/About/About'))
const NotFound = lazy(() => import('src/components/pages/NotFound/NotFound'))
const Contact = lazy(() => import('src/components/pages/Contact/Contact'))
const Home = lazy(() => import('src/components/pages/Home/Home'))
const SearchResults = lazy(() =>
  import('src/components/pages/SearchResults/SearchResults')
)
const DeveloperIntegration = lazy(() => import('src/components/pages/DeveloperIntegration/DeveloperIntegration'))
const WhyDifferent = lazy(() =>
  import('src/components/pages/WhyDifferent/WhyDifferent')
)
const ThankYou = lazy(() =>
  import('src/components/pages/ThankYou/ThankYou')
)
const Releases = lazy(() => import('src/components/pages/Releases/Releases'))
const Search = lazy(() => import('src/components/pages/Search/Search'))
const Routing = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <Suspense fallback={<Loading background='none' />}>
            <Search />
          </Suspense>
        }
      />
      {!process.env.REACT_APP_PROMO_VERSION && (
        <>
          <Route
            path='/style-guide'
            element={
              <Suspense fallback={<Loading background='none' />}>
                <StyleGuide />
              </Suspense>
            }
          />
          <Route
            path='charitabletrust'
            element={
              <Suspense fallback={<Loading background='none' />}>
                <CharitableTrust />
              </Suspense>
            }
          />
          <Route
            path='whydifferent'
            element={
              <Suspense fallback={<Loading background='none' />}>
                <WhyDifferent />
              </Suspense>
            }
          />
          <Route
            path='managedata'
            element={
              <Suspense fallback={<Loading background='none' />}>
                <RemoveInfo />
              </Suspense>
            }
          />
          <Route
            path='howto'
            element={
              <Suspense fallback={<Loading background='none' />}>
                <HowTo />
              </Suspense>
            }
          />
          <Route
        path='news'
        element={
          <Suspense fallback={<Loading background='none' />}>
            <News />
          </Suspense>
        }
      />
       <Route
        path='releases'
        element={
          <Suspense fallback={<Loading background='none' />}>
            <Releases />
          </Suspense>
        }
      />
          <Route
            path='privacy'
            element={
              <Suspense fallback={<Loading background='none' />}>
                <Privacy />
              </Suspense>
            }
          />
           <Route
            path='checkout'
            element={
              <Suspense fallback={<Loading background='none' />}>
                <Checkout />
              </Suspense>
            }
          />
          <Route
            path='terms'
            element={
              <Suspense fallback={<Loading background='none' />}>
                <Terms />
              </Suspense>
            }
          />
          <Route
            path='Faq'
            element={
              <Suspense fallback={<Loading background='none' />}>
                <Faq />
              </Suspense>
            }
          />
          <Route
            path='about'
            element={
              <Suspense fallback={<Loading background='none' />}>
                <About />
              </Suspense>
            }
          />
          <Route
            path='solutions'
            element={
              <Suspense fallback={<Loading background='none' />}>
                <CorporateSolutions />
              </Suspense>
            }
          />
          <Route
            path='contact'
            element={
              <Suspense fallback={<Loading background='none' />}>
                <Contact />
              </Suspense>
            }
          />
          <Route
            path='search-results'
            element={
              <Suspense fallback={<Loading background='none' />}>
                <SearchResults />
              </Suspense>
            }
          />
        </>
      )}
      <Route
        path='*'
        element={
          <Suspense fallback={<Loading background='none' />}>
            <NotFound />
          </Suspense>
        }
      />
      <Route
        path='developer'
        element={
          <Suspense fallback={<Loading background='none' />}>
            <DeveloperIntegration />
          </Suspense>
        }
      />
      <Route
        path='thank-you'
        element={
          <Suspense fallback={<Loading background='none' />}>
            <ThankYou/>
          </Suspense>
        }
      />
    </Routes>
  )
}

export default Routing
