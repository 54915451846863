import { string, number } from 'prop-types';

const ArrowDown = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 72 72'>
			<path
				d='M22.05,30.77a1.08,1.08,0,0,1-.45-.9,1.6,1.6,0,0,1,.34-1l.9-.79a1.06,1.06,0,0,1,.9-.45,1.4,1.4,0,0,1,1,.45L36,39.54,47.25,28.07a1.62,1.62,0,0,1,1-.34,1.18,1.18,0,0,1,.9.34l.9.79a1.6,1.6,0,0,1,.34,1,1.21,1.21,0,0,1-.34.9L37,44a1.67,1.67,0,0,1-2,0h.11Z'
				fill={color}
			/>
		</svg>
	);
};
export default ArrowDown;

ArrowDown.defaultProps = {
	color: 'white',
	width: 25,
};

ArrowDown.propTypes = {
	className: string,
	color: string,
	width: number,
};
