const Ring = ({ fill, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 71.418 71.418'
      fill={fill || 'currentColor'}
      {...props}
    >
      <path
        data-name='Path 297165'
        d='M35.709 0a35.709 35.709 0 1 0 35.71 35.71A35.749 35.749 0 0 0 35.709 0zm0 63.953A28.244 28.244 0 1 1 63.953 35.71a28.276 28.276 0 0 1-28.244 28.243z'
        transform='rotate(-90 35.709 35.709)'
      />
    </svg>
  )
}

export default Ring
