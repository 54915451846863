import { combineReducers } from 'redux'
import errorReducer from './errorReducer';
import qualityReducer from './qualityReducer';
import searchReducer from './searchReducer';
import videoReducer from './videoReducer';
import sessionReducer from './sessionReducer';
import promoReducer from './promoReducer';
import stripeReducer from './stripeReducer';
import contactReducer from './contactUsReducer';

export default combineReducers({
  error: errorReducer,
  search: searchReducer,
  video: videoReducer,
  quality: qualityReducer,
  session: sessionReducer,
  promo: promoReducer,
  stripe: stripeReducer,
  contact: contactReducer,
})
