import React from 'react';
import classnames from 'classnames';
import { string, bool, node } from 'prop-types';

const LabelQuote = ({ className, mobile, children, name, city, state }) => {
	return (
		<div className={classnames(className, 'flex font-body w-80')}>
			<div
				className={classnames(
					'font-secondary-regular tracking-wide text-blue-secondary mr-1',
					mobile ? 'text-6xl' : 'text-7xl '
				)}>
				“
			</div>
			<div
				className={classnames('grid gap-4', mobile ? 'text-xxs' : 'text-xs')}>
				<div>{''}</div>
				<div>{children}</div>
				<div className='flex'>
					<span className='font-bold'>{name}</span>{' '}
					<span className='mx-2'>|</span>
					<span>
						{city}, <span className='uppercase'>{state}</span>
					</span>
				</div>
			</div>
		</div>
	);
};
export default LabelQuote;

LabelQuote.defaultProps = {
	children: '',
};

LabelQuote.prototypes = {
	className: string,
	children: node,
	name: string,
	location: string,
	mobile: bool,
};
