const CloseRing = ({ fill, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 71.201 71.201'
      fill={fill || 'currentColor'}
      {...props}
    >
      <g transform='translate(-294.114 -173.383)'>
        <g transform='translate(294.114 173.383)'>
          <path
            d='M329.714 244.584a35.6 35.6 0 1 1 35.6-35.6 35.64 35.64 0 0 1-35.6 35.6zm0-64.963a29.362 29.362 0 1 0 29.362 29.362 29.395 29.395 0 0 0-29.362-29.362z'
            transform='translate(-294.114 -173.383)'
          />
        </g>
        <path
          d='m339.624 214.482 10.806-10.806a3.119 3.119 0 1 0-4.41-4.412l-10.808 10.806-10.807-10.807a3.119 3.119 0 0 0-4.41 4.412l10.805 10.807-10.38 10.381a3.119 3.119 0 1 0 4.41 4.411l10.382-10.381L346.02 229.7a3.119 3.119 0 1 0 4.41-4.411z'
          transform='translate(-5.498 -5.498)'
        />
      </g>
    </svg>
  )
}

export default CloseRing
