import { Fragment, useMemo, useState } from 'react'
import { NavLink as Link, useLocation } from 'react-router-dom'
import { ArrowRight } from '../../atoms/SVG'
import { combinedStyles } from './styles'
import { navItems } from './navItems'
import classnames from 'classnames'

export const NavLinkMobile = ({ key, onClick, to, label, subnav, id }) => {
  const { pathname } = useLocation()

  // Return the ID of the active parent
  const activeParentRouteId = useMemo(
    () =>
      navItems.find((item) => {
        const foundChild = item?.subnav?.find((child) => child.to === pathname)
        return !!item.subnav && !!foundChild
      })?.id || undefined,
    [pathname]
  )

  const [navGroupOpen, setNavGroupOpen] = useState(activeParentRouteId === id)
  const toggle = () => setNavGroupOpen(!navGroupOpen)

  return (
    <Fragment>
      <div className='flex justify-center gap-x-8 w-full py-9 border-b-4 border-cdcdcdb'>
        <span className='relative'>
          <Link className={combinedStyles.parentLinkMobile} to={to} key={key}>
            <span onClick={onClick}>{label}</span>
          </Link>
          {!!subnav && (
            <ArrowRight
              onClick={toggle}
              width={10}
              fill={navGroupOpen ? '#07d80c' : '#253933'}
              // fill='url(#logo-gradient)'
              className={classnames(
                'absolute inset-y-1 -right-6',
                'cursor-pointer transition transform',
                navGroupOpen && 'rotate-90'
              )}
            />
          )}
        </span>
      </div>
      {navGroupOpen &&
        subnav.map((subnavEl) => (
          <Link
            to={subnavEl.to}
            onClick={onClick}
            className={combinedStyles.subNavElementMobile}>
            {subnavEl.label}
          </Link>
        ))}
    </Fragment>
  )
}
