const SadWindow = () => {
  return (
    <svg width="130" height="129" viewBox="0 0 130 129" fill="none">
      <path
        d="M109.528 0H20.4716C9.4592 0 0.5 8.9592 0.5 19.9716V109.029C0.5 120.041 9.4592 129 20.4716 129H109.529C120.541 129 129.5 120.041 129.5 109.028V19.9716C129.5 8.9592 120.541 0 109.528 0ZM121.941 109.028C121.941 115.873 116.373 121.441 109.528 121.441H20.4716C13.627 121.441 8.05859 115.873 8.05859 109.028V39.9429H121.941V109.028ZM121.941 32.3843H8.05859V19.9716C8.05859 13.127 13.627 7.55859 20.4716 7.55859H109.529C116.373 7.55859 121.941 13.127 121.941 19.9716V32.3843Z"
        fill="url(#paint0_linear_87_3937)"
      />
      <path
        d="M24.519 16.1924H20.4711C15.4643 16.3771 15.4638 23.566 20.4711 23.751H24.519C29.5258 23.566 29.5263 16.3773 24.519 16.1924Z"
        fill="url(#paint1_linear_87_3937)"
      />
      <path
        d="M42.736 16.1924H38.6879C33.6811 16.3771 33.6806 23.566 38.6879 23.751H42.736C47.7426 23.566 47.7434 16.3773 42.736 16.1924Z"
        fill="url(#paint2_linear_87_3937)"
      />
      <path
        d="M60.9519 16.1924H56.9037C51.8969 16.3771 51.8964 23.566 56.9037 23.751H60.9519C65.9584 23.566 65.9592 16.3773 60.9519 16.1924Z"
        fill="url(#paint3_linear_87_3937)"
      />
      <path
        d="M61.5992 80.5724C63.474 78.6984 66.5251 78.6984 68.3999 80.5724C69.8761 82.0481 72.2694 82.0471 73.7446 80.5709C75.22 79.0947 75.2195 76.7017 73.7431 75.2263C68.9219 70.4077 61.0771 70.4077 56.256 75.2263C52.8458 78.8967 57.926 83.9814 61.5992 80.5724Z"
        fill="url(#paint4_linear_87_3937)"
      />
      <path
        d="M93.3359 68.279C95.4231 68.279 97.1152 66.5869 97.1152 64.4997V60.4516C96.9305 55.4448 89.7416 55.4443 89.5566 60.4516V64.4997C89.5566 66.5869 91.2488 68.279 93.3359 68.279Z"
        fill="url(#paint5_linear_87_3937)"
      />
      <path
        d="M36.6641 68.279C38.7512 68.279 40.4434 66.5869 40.4434 64.4997V60.4516C40.2587 55.4448 33.0697 55.4443 32.8848 60.4516V64.4997C32.8848 66.5869 34.5769 68.279 36.6641 68.279Z"
        fill="url(#paint6_linear_87_3937)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_87_3937"
          x1="65.7588"
          y1="5.31176"
          x2="65.7588"
          y2="128.241"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E8E867" />
          <stop offset="0.236486" stop-color="#B2E573" />
          <stop offset="1" stop-color="#2ADA9D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_87_3937"
          x1="22.563"
          y1="16.5036"
          x2="22.7322"
          y2="32.2501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E8E867" />
          <stop offset="0.514386" stop-color="#B2E573" />
          <stop offset="1" stop-color="#2ADA9D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_87_3937"
          x1="40.7799"
          y1="16.5036"
          x2="40.9491"
          y2="32.2501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E8E867" />
          <stop offset="0.514386" stop-color="#B2E573" />
          <stop offset="1" stop-color="#2ADA9D" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_87_3937"
          x1="58.9957"
          y1="16.5036"
          x2="59.1649"
          y2="32.2501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E8E867" />
          <stop offset="0.514386" stop-color="#B2E573" />
          <stop offset="1" stop-color="#2ADA9D" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_87_3937"
          x1="64.9993"
          y1="60.9475"
          x2="64.7619"
          y2="85.3078"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E8E867" />
          <stop offset="0.472517" stop-color="#B2E573" />
          <stop offset="1" stop-color="#2ADA9D" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_87_3937"
          x1="93.2484"
          y1="50.5613"
          x2="93.2484"
          y2="72.4257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E8E867" />
          <stop offset="0.418675" stop-color="#B2E573" />
          <stop offset="1" stop-color="#2ADA9D" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_87_3937"
          x1="36.5765"
          y1="50.5613"
          x2="36.5765"
          y2="72.4257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E8E867" />
          <stop offset="0.418675" stop-color="#B2E573" />
          <stop offset="1" stop-color="#2ADA9D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SadWindow;
