import { createContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "src/hooks";
import Modal from "src/components/organisms/Modal/Modal";
import { RESET_SEARCH } from "src/redux/actions/types";

const ModalContext = createContext(null);

const ModalProvider = ({ children }) => {
  const { isOpen, setModal, closeModal, content } = useModal();
  const [searchType, setSearchType] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [searchCaseID, setSearchCaseID] = useState({});
  const [searchEnchacedData, setSearchEnchacedData] = useState({});
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const dispatch = useDispatch();

  const reset = () => {
    setSearchType(null);
    setSearchData({});
    setIsPaymentSuccess(false);
    dispatch({ type: RESET_SEARCH });
  };

  const value = {
    isOpen,
    setModal,
    closeModal: () => {reset(); closeModal();},
    content,
    searchType,
    setSearchType,
    searchData,
    setSearchData,
    isPaymentSuccess,
    setIsPaymentSuccess,
    searchEnchacedData,
    setSearchEnchacedData,
    searchCaseID,
    setSearchCaseID,
    reset,
  };

  return (
    <ModalContext.Provider value={value}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
