import React from 'react';
import { string, func } from 'prop-types';

const TextBody = ({ children, onClick, className }) => {
	return (
		<span
			className={className}
			onClick={() => {
				if (onClick) {
					onClick();
				}
			}}>
			{children}
		</span>
	);
};
export default TextBody;

TextBody.propTypes = {
	children: string,
	onClick: func,
};
