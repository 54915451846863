import { Close } from 'src/components/atoms/SVG'

const CloseButton = ({ onClick, className }) => {
  return (
    <button className={className} onClick={onClick}>
      <Close className={className} />
    </button>
  )
}

export default CloseButton
