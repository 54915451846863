import classnames from 'classnames'
import { TextVariants } from 'src/components/atoms/Text/Text'

const Label = ({ className, children, name }) => {
  return (
    <label
      className={classnames(
        TextVariants.h7,
        'uppercase text-left whitespace-nowrap mb-0.5 sm:mb-1',
        className
      )}
      name={name}
      htmlFor={name}
    >
      {children}
    </label>
  )
}

export default Label
