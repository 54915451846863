const Warning = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9996 13.6V12.6H8.99961V13.6H10.9996ZM8.99961 13.612V14.612H10.9996V13.612H8.99961ZM8.99961 5.8V10.6H10.9996V5.8H8.99961ZM8.99961 13.6V13.612H10.9996V13.6H8.99961ZM9.99961 17.4C5.9127 17.4 2.59961 14.0869 2.59961 10H0.599609C0.599609 15.1915 4.80813 19.4 9.99961 19.4V17.4ZM17.3996 10C17.3996 14.0869 14.0865 17.4 9.99961 17.4V19.4C15.1911 19.4 19.3996 15.1915 19.3996 10H17.3996ZM9.99961 2.6C14.0865 2.6 17.3996 5.91309 17.3996 10H19.3996C19.3996 4.80852 15.1911 0.599998 9.99961 0.599998V2.6ZM9.99961 0.599998C4.80813 0.599998 0.599609 4.80852 0.599609 10H2.59961C2.59961 5.91309 5.9127 2.6 9.99961 2.6V0.599998Z"
        fill="#DA0000"
      />
    </svg>
  );
};

export default Warning;
