import { useLayoutEffect } from 'react'
import { useWindowWidth } from './'

export const useLockBodyScrollMobileOnly = () => {
  const [width] = useWindowWidth()

  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow

    if (width < 1024) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = originalStyle
    }

    return () => (document.body.style.overflow = originalStyle)
  }, [width])
}
