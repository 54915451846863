import React from 'react'
import classnames from 'classnames'
import { string, func, bool, oneOf, node } from 'prop-types'
import { TextHeadingVariantsHTML } from '../../../constants/types'

const tailwindClass = (headingVariant) => {
  switch (headingVariant) {
    case 'h1':
      return 'mdMax:text-4xl mdMin:text-5xl'
    case 'h2':
      return 'mdMax:text-3xl mdMin:text-4xl'
    case 'h3':
      return 'mdMax:text-2xl mdMin:text-3xl'
    case 'h4':
      return 'mdMax:text-lg mdMin:text-2xl'
    case 'h6':
      return ''
    default:
      return 'mdMax:text-4xl mdMin:text-5xl'
  }
}

const TextHeading = ({ children, headingVariant, onClick, className }) => {
  const Element = headingVariant
  return (
    <Element
      className={classnames(tailwindClass(headingVariant), className)}
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
    >
      {children}
    </Element>
  )
}
export default TextHeading

TextHeading.defaultProps = {
  headingVariant: 'h1',
}

TextHeading.propTypes = {
  children: node,
  className: string,
  headingVariant: oneOf(TextHeadingVariantsHTML),
  mobile: bool,
  onClick: func,
}
