import React, { useMemo, useState } from 'react'
import { NavLink as Link, useLocation } from 'react-router-dom'
import { navItems } from './navItems'
import { styles } from './styles'
import { NavLinkMobile } from './NavLinkMobile'
import { useLockBodyScrollMobileOnly } from '../../../hooks/useLockBodyScrollMobileOnly'
import LogoImg from 'src/components/atoms/Logo/LogoImg'
import classnames from 'classnames'

const navfooterLinks = [
  {
    label: 'Terms of Use',
    to: '/terms',
  },
  {
    label: 'Privacy Policy',
    to: '/privacy',
  },
]

const MobileNavFooter = ({ onClick }) => {
  return (
    <div className='flex flex-col items-center gap-y-4'>
      <LogoImg className='w-20 mb-4 place-content-start' />
      <div className='flex flex-row gap-x-3'>
        {navfooterLinks.map((link, i) => (
          <Link
            key={i}
            className={classnames(
              styles.link.footer,
              i === 0 && 'pr-3 border-r-2 border-cdcdcd'
            )}
            to={link.to}
            onClick={onClick}>
            {link.label}
          </Link>
        ))}
      </div>
      <span className='text-xs'>2022 Verify. All Rights Reserved.</span>
    </div>
  )
}

export const NavMenuMobile = ({ onClick }) => {
  useLockBodyScrollMobileOnly()

  return (
    <nav
      className='w-full overflow-y-auto pb-28 px-6 xxsMax:px-0 flex flex-col items-center justify-between text-center gap-y-12 bg-white-primary'
      style={{ height: '100vh' }}>
      <div className='w-full flex flex-col items-center'>
        {navItems
          .filter(({ label }) => typeof label === 'string')
          .map((route, i) => (
            <NavLinkMobile
              {...route}
              onClick={onClick}
              key={`Mobile__Subnav--${i}`}
            />
          ))}
      </div>

      <MobileNavFooter onClick={onClick} />
    </nav>
  )
}
