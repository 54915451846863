import React, { useRef } from 'react'
import { Formik } from 'formik'
import { Text } from '../../molecules/Text/index'
import GradientSpinner from '../../atoms/Loader/GradientSpinner'
// import PageContainer from '../../organisms/Container/PageContainer'
import { SelectField } from '../../atoms/Input/SelectField'
import { stateOptions } from '../../../utils/selectOptions'
import Button from '../../atoms/ButtonCta'

const initialValuesSampleForm = {
  state: '',
}

const StyleGuide = () => {
  const formRef = useRef(null)

  return (
    <div className='px-2 bg-white-primary'>
      <Text
        variant='header'
        className='text-center italic mb-8 border-b border-black-primary'>
        Style Guide
      </Text>

      {/* Typography */}
      <Text variant='header' className='underline'>
        Typography
      </Text>
      <div className='grid gap-4 py-4'>
        {/* MAIN HEADINGS */}
        <div className='grid grid-cols-2 border-b'>
          <Text variant='body'>H1 Poppins Bold:</Text>
          <Text variant='header'>Header</Text>
        </div>
        <div className='grid grid-cols-2 border-b'>
          <Text variant='body'>H2 Poppins Bold:</Text>
          <Text variant='header-2'>Secondary Header</Text>
        </div>
        <div className='grid grid-cols-2 border-b'>
          <Text variant='body'>H3 DM Sans Medium:</Text>
          <Text variant='header-3'>Section Headers</Text>
        </div>
        <div className='grid grid-cols-2 border-b'>
          <Text variant='body'>H4 Poppins Reg:</Text>
          <Text variant='header-4'>Hero Subhead</Text>
        </div>

        {/* H6 HEADINGS */}
        <div className='grid grid-cols-2 border-b'>
          <Text variant='body'>H6 DM Sans Medium:</Text>
          <Text variant='header-pop-up'>POP UP HEADER</Text>
        </div>
        <div className='grid grid-cols-2 border-b'>
          <Text variant='body'>H6 Poppins Reg:</Text>
          <Text variant='header-article'>
            NAV, DROP DOWN, AND ARTICLE HEADERS
          </Text>
        </div>
        <div className='grid grid-cols-2 border-b'>
          <Text variant='body'>H6 DM Sans Reg:</Text>
          <Text variant='header-footer'>FOOTER LINKS</Text>
        </div>
        <div className='grid grid-cols-2 border-b'>
          <Text variant='body'>H6 Poppins Bold:</Text>
          <Text variant='header-text-field'>TEXT FIELD HEADER</Text>
        </div>

        {/* BODY TEXT */}
        <div className='grid grid-cols-2 border-b'>
          <Text variant='body'>B1 Poppins Bold:</Text>
          <Text variant='body'>BUTTON CTA</Text>
        </div>
        <div className='grid grid-cols-2 border-b'>
          <Text variant='body'>B2 DM Sans Reg:</Text>
          <Text variant='body-2'>Body copy paragraph and descriptions</Text>
        </div>
      </div>

      {/* Loading Spinner */}
      <Text variant='header' className='underline mt-8'>
        Loader
      </Text>
      <div className='grid place-items-center'>
        <div className='p-10 bg-grey-secondary rounded-xl my-4 w-2/3'>
          <div className='shadow-inner-lg py-4 rounded-xl grid place-items-center gap-4 bg-grey-secondary'>
            <GradientSpinner bgColorClass='grey-secondary' />
            <Text variant='header-4' className='text-white-primary text-center'>
              Please wait while video is loading...
            </Text>
          </div>
        </div>
      </div>

      {/* Form Inputs */}
      <Text variant='header' className='underline mt-8'>
        Form Inputs
      </Text>

      <div className='grid gap-4 py-4'>
        <Formik
          // validationSchema={{}}
          validateOnMount
          initialValues={initialValuesSampleForm}
          onSubmit={() => {}}
          innerRef={formRef}>
          {({
            values,
            errors,
            touched,
            setFieldValue,
            getFieldProps,
            setFieldTouched,
          }) => {
            return (
              <form>
                <SelectField
                  className='w-1/2'
                  required
                  label='Location (Optional)'
                  placeholder='STATE'
                  name='state'
                  options={stateOptions.map(({ label, value }) => ({
                    label: label.toUpperCase(),
                    value: value,
                  }))}
                  field={getFieldProps('location')}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                />
              </form>
            )
          }}
        </Formik>
        <Button text='SEND' className='w-1/2' />
      </div>
    </div>
  )
}
export default StyleGuide
