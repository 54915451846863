import { string, number } from 'prop-types';

const MousePointer = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 22.589 36.142'>
			<defs>
				<linearGradient
					id='a'
					x1='.5'
					x2='.5'
					y2='1'
					gradientUnits='objectBoundingBox'>
					<stop offset='0' stopColor='#efea5a' />
					<stop offset='.5' stopColor='#91e474' />
					<stop offset='1' stopColor='#00da9d' />
				</linearGradient>
			</defs>
			<path
				d='m10.881 34.479-2.958-7.19-3.3 3.727A2.647 2.647 0 0 1 0 29.189V2.722A2.647 2.647 0 0 1 4.574.845l17.277 18.526a2.726 2.726 0 0 1-1.92 4.595h-4.638l3.022 7.344a2.737 2.737 0 0 1-1.445 3.573l-2.541 1.059a2.631 2.631 0 0 1-3.447-1.462zM8.634 23.08l4.337 10.54a.377.377 0 0 0 .489.236L16 32.8a.487.487 0 0 0 .225-.628l-4.3-10.463h8.011a.471.471 0 0 0 .268-.8L2.922 2.386c-.255-.273-.663-.065-.663.336v26.467c0 .408.419.612.67.328l5.7-6.437z'
				fill={color || 'url(#a)'}
			/>
		</svg>
	);
};
export default MousePointer;

MousePointer.defaultProps = {
	color: undefined,
	width: 30,
};

MousePointer.propTypes = {
	className: string,
	color: string,
	width: number,
};
