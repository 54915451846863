const HamburgerIcon = ({ className, fill, stroke, ...props }) => {
  return (
    <svg
      className={className}
      viewBox='0 0 24 24'
      fill={fill || 'currentColor'}
      stroke={stroke || 'currentColor'}
      {...props}
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M4 6h16M4 12h16M4 18h16'
      />
    </svg>
  )
}
export default HamburgerIcon
