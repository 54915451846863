import {
  SESSION_ACTIONS
} from "../actions/types";

import {
  loadState,
  saveState
} from './../../utils/persistedState';


const persistedState = loadState('session');
const defaultState = {
  startTime: null,
  maxTime: 600000,
  queriesCount: 0,
  maxQueriesCount: 1,
  email: null,
};
const initialState = persistedState || defaultState;

export default function startSession(state = initialState, {
  type,
  payload
}) {
  switch (type) {
    case SESSION_ACTIONS.START: {
      let now = Date.now();
      let canMakeFreeSearchSelector = (state && state.startTime 
        && ((now - state.startTime) <= state.maxTime)
        && (state.queriesCount <= 1));
        console.log('SESSION_ACTIONS.START', canMakeFreeSearchSelector)

        // console.log('___', {
        //   a: (state && state.startTime 
        //     && ((now - state.startTime) <= state.maxTime)
        //     && (state.queriesCount <= state.maxQueriesCount)),
        //     b:state && state.startTime,
        //       c: ((now - state.startTime) <= state.maxTime),
        //         d: (state.queriesCount <= state.maxQueriesCount)
        // });


      if(!state.startTime || !canMakeFreeSearchSelector) {
        state.startTime = Date.now();
        state.queriesCount = 0;
      }
      state.queriesCount=++state.queriesCount;
      saveState(state, 'session');
      return {
        ...state,
        status: "loading",
      };
    }

    case SESSION_ACTIONS.FINISH: {
      // console.log('SESSION_ACTIONS.FINISH')
      state.queriesCount = 0;
      state = defaultState;
      saveState(state, 'session');
      return {
        ...state,
        status: "finish",
      };
    }

    case SESSION_ACTIONS.SET_EMAIL: {
      // console.log('SESSION_ACTIONS.START')
      state.email = payload;
      saveState(state, 'session');
      return {
        ...state,
        status: "finish",
      };
    }

    case SESSION_ACTIONS.INCREMENT_SEARCH: {
      // console.log('SESSION_ACTIONS.START')
      state.email = payload;
      saveState(state, null);
      return {
        ...state,
        status: "finish",
      };
    }

    default:
      return state;
  }
}