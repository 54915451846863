import { string, number } from 'prop-types';

const MobilIcon = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 22.589 36.142'>
			<defs>
				<linearGradient
					id='a'
					x1='.5'
					x2='.5'
					y2='1'
					gradientUnits='objectBoundingBox'>
					<stop offset='0' stopColor='#efea5a' />
					<stop offset='.5' stopColor='#91e474' />
					<stop offset='1' stopColor='#00da9d' />
				</linearGradient>
			</defs>
			<path
				d='M13.836 31.625H8.753a.85.85 0 0 1-.847-.847v-.565a.85.85 0 0 1 .847-.847h5.082a.85.85 0 0 1 .847.847v.565a.85.85 0 0 1-.846.847zm8.753-28.237v29.366a3.389 3.389 0 0 1-3.389 3.388H3.388A3.389 3.389 0 0 1 0 32.754V3.388A3.389 3.389 0 0 1 3.388 0H19.2a3.389 3.389 0 0 1 3.389 3.388zm-2.259 0a1.133 1.133 0 0 0-1.13-1.129H3.388a1.133 1.133 0 0 0-1.129 1.129v29.366a1.133 1.133 0 0 0 1.129 1.129H19.2a1.133 1.133 0 0 0 1.129-1.129z'
				fill={color || 'url(#a)'}
			/>
		</svg>
	);
};
export default MobilIcon;

MobilIcon.defaultProps = {
	color: undefined,
	width: 30,
};

MobilIcon.propTypes = {
	className: string,
	color: string,
	width: number,
};
