const GradientDefs = () => (
  <svg className='w-0 h-0'>
    <defs>
      <linearGradient
        id='logo-gradient'
        x1='11.551'
        y1='.973'
        x2='11.551'
        y2='-.085'
        gradientUnits='objectBoundingBox'
      >
        <stop offset='.048' stopColor='#efea5a' />
        <stop offset='.399' stopColor='#00da9d' />
        <stop offset='.836' stopColor='#10312b' />
      </linearGradient>

      <linearGradient
        id='verify-gradient'
        x1='.5'
        x2='.5'
        y2='1'
        gradientUnits='objectBoundingBox'
      >
        <stop offset='0' stopColor='#efea5a' />
        <stop offset='.5' stopColor='#91e474' />
        <stop offset='1' stopColor='#00da9d' />
      </linearGradient>
    </defs>
  </svg>
)

export default GradientDefs
