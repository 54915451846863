import { string, number } from 'prop-types';

const Clipboard = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 72 72'>
			<path
				d='M48.15,19.8a4,4,0,0,1,4.05,4.05v29.7a4,4,0,0,1-4.05,4.05H23.85a4,4,0,0,1-4.05-4.05V23.85a4,4,0,0,1,4.05-4.05h7.51a2.63,2.63,0,0,1-.08-.68,4.72,4.72,0,0,1,9.44,0,2.63,2.63,0,0,1-.08.68ZM49.5,53.55V23.85a1.3,1.3,0,0,0-1.35-1.35h-4v1.69a1,1,0,0,1-1,1H28.91a1,1,0,0,1-1-1V22.5h-4a1.3,1.3,0,0,0-1.35,1.35v29.7a1.3,1.3,0,0,0,1.35,1.35h24.3a1.3,1.3,0,0,0,1.35-1.35ZM29.25,34a2,2,0,1,1-1.43.6A1.93,1.93,0,0,1,29.25,34Zm0,8.11a2,2,0,1,1-1.43.59A1.92,1.92,0,0,1,29.25,42.08Zm14.17-7.43a.72.72,0,0,1,.68.68v1.34a.72.72,0,0,1-.68.68H34a.72.72,0,0,1-.67-.68V35.33a.72.72,0,0,1,.67-.68Zm0,8.1a.72.72,0,0,1,.68.67v1.36a.72.72,0,0,1-.68.67H34a.72.72,0,0,1-.67-.67V43.42a.72.72,0,0,1,.67-.67ZM36,17.1a2,2,0,0,0-1.43.59,2,2,0,0,0,0,2.87,2,2,0,0,0,2.86,0,2,2,0,0,0,0-2.87A2,2,0,0,0,36,17.1Z'
				fill={color}
			/>
		</svg>
	);
};
export default Clipboard;

Clipboard.defaultProps = {
	color: 'white',
	width: 30,
};

Clipboard.propTypes = {
	className: string,
	color: string,
	width: number,
};
