import { createPortal } from "react-dom";
import { useContext } from "react";
import { ModalContext } from "src/components/contexts/ModalContext";
import PropTypes from "prop-types";
import classnames from "classnames";
// import { useClickOutside } from 'src/hooks'
import Text from "src/components/atoms/Text/Text";
import CloseButton from "src/components/molecules/Button/CloseButton";
import classes from "./Modal.module.css";

const styles = {
  fullscreen: "w-screen h-full fixed inset-0",
  flexCenter: "flex items-center justify-center",
  overlay: `bg-black-secondary bg-opacity-90 z-10`,
  wrapper: `z-20`,
  container:
    "max-w-sm w-10/12 sm:w-auto sm:max-w-none bg-e5e5e5 rounded-lg relative",
  header: {
    container: "bg-white-primary py-4 px-4 sm:px-14 rounded-t-lg sm:max-w-3xl text-center",
    button:
      "w-3 sm:w-4 leading-3 self-start absolute top-2.5 right-2.5 sm:top-3 sm:right-3 text-787878 z-0",
    content: "w-full relative z-10",
  },
  content: {
    container: "",
    padding: {
      default: "py-4 px-8",
      large: "py-8 sm:py-12  px-4 sm:px-14",
      xlarge: "py-10 sm:py-12  px-8 sm:px-20",
    },
  },
};

export const ModalContainer = ({ className, header, padding, children }) => {
  const { closeModal } = useContext(ModalContext);
  return (
    <div className={classnames(styles.container, className, classes.container)}>
      <div className={classnames(styles.header.container, styles.flexCenter)}>
        {typeof header === "string" ? (
          <Text className="uppercase mr-4 sm:mr-0" variant="h5">
            {header}
          </Text>
        ) : (
          <div className={styles.header.content}>{header}</div>
        )}
        <CloseButton className={styles.header.button} onClick={closeModal} />
      </div>

      <div className={styles.content.padding[padding]}>{children}</div>
    </div>
  );
};

ModalContainer.propTypes = {
  padding: PropTypes.oneOf(Object.keys(styles.content.padding)),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  header: PropTypes.node,
};

ModalContainer.defaultProps = {
  padding: "default",
};

const Modal = () => {
  const { isOpen, content } = useContext(ModalContext);
  // const ref = useClickOutside(closeModal)

  return isOpen
    ? createPortal(
        <>
          <div className={classnames(styles.overlay, styles.fullscreen)} />
          <div
            className={classnames(
              styles.wrapper,
              "w-screen h-screen fixed inset-0",
              "flex items-center justify-center"
            )}
            aria-modal={true}
            aria-hidden={true}
            tabIndex={-1}
          >
            {/* <div ref={ref}> */}
            {content}
            {/* </div> */}
          </div>
        </>,
        document.body
      )
    : null;
};

export default Modal;
