import { string, number } from 'prop-types';

const LogoutIcon = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 72 72'>
			<path
				d='M12,22.5A4.47,4.47,0,0,1,16.5,18H28.88A1.13,1.13,0,0,1,30,19.12v.76A1.13,1.13,0,0,1,28.88,21H16.5A1.44,1.44,0,0,0,15,22.5v27A1.44,1.44,0,0,0,16.5,51H28.88A1.13,1.13,0,0,1,30,52.12v.76A1.13,1.13,0,0,1,28.88,54H16.5A4.47,4.47,0,0,1,12,49.5Zm12,9.84a4.49,4.49,0,0,1,4.5-4.5H39V22a3.42,3.42,0,0,1,1.12-2.62,4,4,0,0,1,2.63-1.08,3.47,3.47,0,0,1,2.63,1.08l13.31,13.4a4.54,4.54,0,0,1,0,6.38L45.38,52.59a3.47,3.47,0,0,1-2.63,1.08,4,4,0,0,1-2.63-1.08A3.42,3.42,0,0,1,39,50V44.16H28.5a4.49,4.49,0,0,1-4.5-4.5Zm3,0v7.32a1.51,1.51,0,0,0,.42,1,1.39,1.39,0,0,0,1.08.47H42V50a.66.66,0,0,0,.47.65.71.71,0,0,0,.84-.18L56.53,37a1.36,1.36,0,0,0,0-2.06L43.31,21.56a.71.71,0,0,0-.84-.18A.66.66,0,0,0,42,22v8.81H28.5a1.39,1.39,0,0,0-1.08.47A1.51,1.51,0,0,0,27,32.34Z'
				fill={color}
			/>
		</svg>
	);
};
export default LogoutIcon;

LogoutIcon.defaultProps = {
	color: 'white',
	width: 30,
};

LogoutIcon.propTypes = {
	className: string,
	color: string,
	width: number,
};
