import React from 'react'
import {  useEffect} from "react";
import { useDispatch} from "react-redux";
import { NavMobile } from './NavMobile'
import { NavDesktop } from './NavDesktop'
import { PROMO_VERSION } from "src/redux/actions/types";

const Navbar = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      dispatch({
        type: PROMO_VERSION.START,
        payload: process.env.REACT_APP_PROMO_VERSION,
      });
    } catch (error) {
      console.error(error);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <header className='relative z-100 flex flex-col items-center bg-white-menu shadow-md'>
      <NavDesktop className='hidden lg:flex max-w-screen-xl w-4/5 py-7 items-center text-090909' />
      <NavMobile className='lg:hidden w-full' />
    </header>
  )
}
export default Navbar
