const EmailMark = ({ defs, fill, ...props }) => {
  return (
    <svg
      width="168"
      height="86"
      viewBox="0 0 168 86"
      fill={fill || 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {defs}
      <g clipPath="url(#clip0_9_1835)">
        <path d="M163.078 0.217613H49.5879C46.8697 0.217613 44.666 2.4002 44.666 5.09236V80.9073C44.666 83.5995 46.8697 85.7821 49.5879 85.7821H163.078C165.797 85.7821 168 83.5995 168 80.9073V5.09236C168 2.4002 165.797 0.217613 163.078 0.217613ZM54.5098 17.0755L87.4043 37.1659L54.5098 66.6669V17.0755ZM114.613 32.2158C114.598 32.2249 114.583 32.2343 114.567 32.2434L106.333 37.2725L98.0674 32.2242C98.0671 32.2242 98.067 32.2239 98.067 32.2239L61.6255 9.9671H151.041L114.613 32.2158ZM96.1288 42.4947L103.75 47.1494C104.542 47.633 105.438 47.8747 106.333 47.8747C107.229 47.8747 108.125 47.633 108.917 47.1494L116.538 42.4947L153.934 76.0329H58.7327L96.1288 42.4947ZM125.262 37.1662L158.157 17.0755V66.6672L125.262 37.1662Z" fill="url(#paint0_linear_9_1835)"/>
        <path d="M29.9001 57.6242H14.7656C12.0474 57.6242 9.84375 59.8068 9.84375 62.499C9.84375 65.1911 12.0474 67.3737 14.7656 67.3737H29.9001C32.6182 67.3737 34.8219 65.1911 34.8219 62.499C34.8219 59.8068 32.6182 57.6242 29.9001 57.6242Z" fill="black"/>
        <path d="M29.9001 57.6242H14.7656C12.0474 57.6242 9.84375 59.8068 9.84375 62.499C9.84375 65.1911 12.0474 67.3737 14.7656 67.3737H29.9001C32.6182 67.3737 34.8219 65.1911 34.8219 62.499C34.8219 59.8068 32.6182 57.6242 29.9001 57.6242Z" fill="url(#paint1_linear_9_1835)"/>
        <path d="M29.9001 38.1253H4.92188C2.20369 38.1253 0 40.3078 0 43C0 45.6922 2.20369 47.8747 4.92188 47.8747H29.9001C32.6183 47.8747 34.8219 45.6922 34.8219 43C34.8219 40.3078 32.6183 38.1253 29.9001 38.1253Z" fill="black"/>
        <path d="M29.9001 38.1253H4.92188C2.20369 38.1253 0 40.3078 0 43C0 45.6922 2.20369 47.8747 4.92188 47.8747H29.9001C32.6183 47.8747 34.8219 45.6922 34.8219 43C34.8219 40.3078 32.6183 38.1253 29.9001 38.1253Z" fill="url(#paint2_linear_9_1835)"/>
        <path d="M29.9001 18.6263H14.7656C12.0474 18.6263 9.84375 20.8089 9.84375 23.501C9.84375 26.1932 12.0474 28.3758 14.7656 28.3758H29.9001C32.6182 28.3758 34.8219 26.1932 34.8219 23.501C34.8219 20.8089 32.6182 18.6263 29.9001 18.6263Z" fill="black"/>
        <path d="M29.9001 18.6263H14.7656C12.0474 18.6263 9.84375 20.8089 9.84375 23.501C9.84375 26.1932 12.0474 28.3758 14.7656 28.3758H29.9001C32.6182 28.3758 34.8219 26.1932 34.8219 23.501C34.8219 20.8089 32.6182 18.6263 29.9001 18.6263Z" fill="url(#paint3_linear_9_1835)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_9_1835" x1="107.059" y1="3.74086" x2="107.059" y2="85.2787" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E8E867"/>
          <stop offset="0.236486" stopColor="#B2E573"/>
          <stop offset="1" stopColor="#2ADA9D"/>
        </linearGradient>
        <linearGradient id="paint1_linear_9_1835" x1="25" y1="53.498" x2="13.5" y2="76.998" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E8E867"/>
          <stop offset="0.452433" stopColor="#B2E573"/>
          <stop offset="1" stopColor="#2ADA9D"/>
        </linearGradient>
        <linearGradient id="paint2_linear_9_1835" x1="21.1292" y1="33.999" x2="12.0263" y2="59.9313" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E8E867"/>
          <stop offset="0.452433" stopColor="#B2E573"/>
          <stop offset="1" stopColor="#2ADA9D"/>
        </linearGradient>
        <linearGradient id="paint3_linear_9_1835" x1="25" y1="14.5" x2="13.5" y2="38" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E8E867"/>
          <stop offset="0.452433" stopColor="#B2E573"/>
          <stop offset="1" stopColor="#2ADA9D"/>
        </linearGradient>
        <clipPath id="clip0_9_1835">
          <rect width="168" height="86" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default EmailMark
