import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import './fonts/DMSans-Regular.ttf'
import App from './components/App'
import './services/sentry'

import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}