import { string, number } from 'prop-types';

const BuyIcon = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 72 72'>
			<path
				d='M46.29,25.71H54V50.14a6.45,6.45,0,0,1-6.43,6.43H24.43A6.45,6.45,0,0,1,18,50.14V25.71h7.71a10.21,10.21,0,0,1,1.37-5.18,10.31,10.31,0,0,1,17.84,0A10.21,10.21,0,0,1,46.29,25.71Zm5.14,24.43V28.29H46.29v3.85a1.29,1.29,0,1,1-2.58,0V28.29H28.29v3.85a1.29,1.29,0,1,1-2.58,0V28.29H20.57V50.14A3.84,3.84,0,0,0,24.43,54H47.57a3.84,3.84,0,0,0,3.86-3.86ZM36,18a7.68,7.68,0,0,0-7.71,7.71H43.71A7.68,7.68,0,0,0,36,18Z'
				fill={color}
			/>
		</svg>
	);
};

export default BuyIcon;

BuyIcon.defaultProps = {
	color: 'white',
	width: 30,
};

BuyIcon.propTypes = {
	className: string,
	color: string,
	width: number,
};
