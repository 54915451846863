import { createPortal } from 'react-dom'
import classnames from 'classnames'
import { Text } from '../../molecules/Text/index'
import GradientSpinner from '../../atoms/Loader/GradientSpinner'

const Loading = ({ message, image, video, background = 'dark' }) => {
  const backgroundVariants = {
    dark: 'bg-black-secondary bg-opacity-90',
    none: 'transparent',
  }
  return createPortal(
    <div
      className={classnames(
        'w-screen h-screen fixed inset-0 flex flex-col items-center justify-center z-20',
        backgroundVariants[background]
      )}
    >
      <div className='relative'>
        {image && (
          <img
            className='absolute rounded-full object-cover w-full h-full'
            src={image}
            alt='loading preview'
          />
        )}
        {video && (
          <video
            className='absolute rounded-full object-cover w-full h-full'
            src={video}
            alt='loading preview'
          />
        )}
        <GradientSpinner />
      </div>
      {message && (
        <Text
          variant='header-4'
          className='text-white-primary text-center mt-6'
        >
          {message}
        </Text>
      )}
    </div>,
    document.body
  )
}

export default Loading
