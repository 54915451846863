import { string, number } from 'prop-types';

const DollarBill = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 72 72'>
			<path
				d='M57.6,21.6A2.42,2.42,0,0,1,60,24V48a2.42,2.42,0,0,1-2.4,2.4H14.4A2.42,2.42,0,0,1,12,48V24a2.42,2.42,0,0,1,2.4-2.4Zm0,19.2V31.2A7.17,7.17,0,0,1,50.4,24H21.6a7.17,7.17,0,0,1-7.2,7.2v9.6A7.17,7.17,0,0,1,21.6,48H19.2a4.61,4.61,0,0,0-1.43-3.38A4.64,4.64,0,0,0,14.4,43.2V48H57.6V43.2A4.87,4.87,0,0,0,52.8,48H50.4a7.17,7.17,0,0,1,7.2-7.2ZM14.4,24v4.8a4.64,4.64,0,0,0,3.37-1.42A4.61,4.61,0,0,0,19.2,24ZM36,27.6a6.26,6.26,0,0,1,3.6,1.13,7.91,7.91,0,0,1,2.63,3.07,9.58,9.58,0,0,1,0,8.4,7.91,7.91,0,0,1-2.63,3.07,6.3,6.3,0,0,1-7.2,0,7.91,7.91,0,0,1-2.63-3.07,9.58,9.58,0,0,1,0-8.4,7.91,7.91,0,0,1,2.63-3.07A6.26,6.26,0,0,1,36,27.6ZM36,42a4.25,4.25,0,0,0,3.38-1.76,7,7,0,0,0,0-8.48,4.13,4.13,0,0,0-6.76,0,7,7,0,0,0,0,8.48A4.25,4.25,0,0,0,36,42ZM57.6,28.8V24H52.8a4.87,4.87,0,0,0,4.8,4.8Z'
				fill={color}
			/>
		</svg>
	);
};

export default DollarBill;

DollarBill.defaultProps = {
	color: 'white',
	width: 30,
};

DollarBill.propTypes = {
	className: string,
	color: string,
	width: number,
};
