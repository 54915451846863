import PropTypes from "prop-types";
import classnames from "classnames";

export const TextVariants = {
  h1: "font-primary text-36 sm:text-75 font-bold", // header
  h2: "font-primary text-27 sm:text-49 font-bold", // secondary header
  h3: "font-secondary-regular text-30 sm:text-40 font-normal", // section headers
  h4: "font-primary text-14 sm:text-30 font-normal", // hero subheader
  h5: "font-secondary-regular text-15 sm:text-20 font-normal", // pop up header
  h6: "font-primary text-15 sm:text-20 font-normal", // secondary nav
  h7: "font-primary font-bold text-11 sm:text-13", // text field header
  cta: "font-primary font-bold text-13 sm:text-18 uppercase leading-none", // cta text
  b1: "font-secondary-regular font-normal text-12 sm:text-17", // Body copy paragraph and descriptions
  b2: "font-secondary-regular font-normal text-11 sm:text-13", // text fields copy
  b3: "font-secondary-regular font-normal text-10 sm:text-xxs", // text fields copy
  input: "font-primary font-medium text-11 sm:text-13",
};

const variantElement = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  h7: "h6",
  label: "label",
  cta: "span",
  b1: "p",
  b2: "p",
  b3: "p",
};

const Text = ({ className, variant, children, ...rest }) => {
  const TextElement = variantElement[variant];

  return (
    <TextElement
      className={classnames(TextVariants[variant], className)}
      {...rest}
    >
      {children}
    </TextElement>
  );
};

Text.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(TextVariants)),
  children: PropTypes.node,
};

Text.defaultProps = {
  variant: "h1",
};

export default Text;
