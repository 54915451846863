import { string, number } from 'prop-types';

const HomeIcon = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 72 72'>
			<path
				d='M56.41,33.67v.08a.28.28,0,0,1,.16.28.8.8,0,0,1-.08.36l-1,1.29a.29.29,0,0,1-.28.16.5.5,0,0,1-.37-.16L51.43,33v18.4a2.52,2.52,0,0,1-.76,1.81,2.48,2.48,0,0,1-1.81.76H39.54a.94.94,0,0,1-.69-.28,1,1,0,0,1-.28-.68V42.43H33.43V53a1,1,0,0,1-.28.68.94.94,0,0,1-.69.28H23.14a2.48,2.48,0,0,1-1.81-.76,2.52,2.52,0,0,1-.76-1.81V33L17.2,35.68a.38.38,0,0,1-.37.12.39.39,0,0,1-.28-.2l-1-1.21a.8.8,0,0,1-.08-.36.5.5,0,0,1,.16-.36l18.8-15.11a2.59,2.59,0,0,1,3.22,0l11.25,9.08v-4a.41.41,0,0,1,.16-.36.51.51,0,0,1,.32-.12H51a.56.56,0,0,1,.32.12.41.41,0,0,1,.16.36v6.11ZM48.86,51.43V30.94L36.32,20.81a.49.49,0,0,0-.64,0L23.14,30.94V51.43h7.72V40.82a1,1,0,0,1,1-1h8.36a1,1,0,0,1,1,1V51.43Z'
				fill={color}
			/>
		</svg>
	);
};

export default HomeIcon;

HomeIcon.defaultProps = {
	color: 'white',
	width: 30,
};

HomeIcon.propTypes = {
	className: string,
	color: string,
	width: number,
};
