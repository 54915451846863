export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const GET_NAME_SEARCH = {
  TRIGGER: 'GET_NAME_SEARCH_TRIGGER',
  SUCCESS: 'GET_NAME_SEARCH_SUCCESS',
  FAIL: 'GET_NAME_SEARCH_FAIL',
}
export const GET_PHONE_SEARCH = {
  TRIGGER: 'GET_PHONE_SEARCH_TRIGGER',
  SUCCESS: 'GET_PHONE_SEARCH_SUCCESS',
  FAIL: 'GET_PHONE_SEARCH_FAIL',
}
export const GET_VECHICLE_SEARCH = {
  TRIGGER: 'GET_VECHICLE_SEARCH_TRIGGER',
  SUCCESS: 'GET_VECHICLE_SEARCH_SUCCESS',
  FAIL: 'GET_VECHICLE_SEARCH_FAIL',
}
export const GET_PHOTO_SEARCH = {
  TRIGGER: 'GET_PHOTO_SEARCH_TRIGGER',
  SUCCESS: 'GET_PHOTO_SEARCH_SUCCESS',
  FAIL: 'GET_PHOTO_SEARCH_FAIL',
}
export const CASE_ID_SEARCH = {
  TRIGGER: 'CASE_ID_SEARCH_TRIGGER',
  SUCCESS: 'CASE_ID_SEARCH_SUCCESS',
  FAIL: 'CASE_ID_SEARCH_FAIL',
}

export const ENHANCED_SEARCH = {
  TRIGGER: 'ENHANCED_SEARCH_TRIGGER',
  SUCCESS: 'ENHANCED_SEARCH_SUCCESS',
  FAIL: 'ENHANCED_SEARCH_FAIL',
}

export const RESET_SEARCH = 'RESET_SEARCH'

export const GET_PHOTO_QUALITY = {
  TRIGGER: 'GET_PHOTO_QUALITY_TRIGGER',
  SUCCESS: 'GET_PHOTO_QUALITY_SUCCESS',
  FAIL: 'GET_PHOTO_QUALITY_FAIL',
}

export const CHANGE_VIDEO_FORMAT = {
  TRIGGER: 'CHANGE_VIDEO_FORMAT_TRIGGER',
  SUCCESS: 'CHANGE_VIDEO_FORMAT_SUCCESS',
  FAIL: 'CHANGE_VIDEO_FORMAT_FAIL',
}

export const GET_WEB_SEARCH = {
  TRIGGER: 'GET_WEB_SEARCH_TRIGGER',
  SUCCESS: 'GET_WEB_SEARCH_SUCCESS',
  FAIL: 'GET_WEB_SEARCH_FAIL',
}

export const GET_NAME_WEB_SEARCH = {
  TRIGGER: 'GET_NAME_WEB_SEARCH_TRIGGER',
  SUCCESS: 'GET_NAME_WEB_SEARCH_SUCCESS',
  FAIL: 'GET_NAME_WEB_SEARCH_FAIL',
}

export const GET_BLOCK = {
  TRIGGER: 'GET_BLOCK_TRIGGER',
  SUCCESS: 'GET_BLOCK_SUCCESS',
  FAIL: 'GET_BLOCK_FAIL',
}

export const GET_SEARCH_RESULT = {
  TRIGGER: 'GET_SEARCH_RESULT_TRIGGER',
  SUCCESS: 'GET_SEARCH_RESULT_SUCCESS',
  FAIL: 'GET_SEARCH_RESULT_FAIL',
}

export const GET_ADDRESS_SEARCH = {
  TRIGGER: 'GET_ADDRESS_SEARCH_TRIGGER',
  SUCCESS: 'GET_ADDRESS_SEARCH_SUCCESS',
  FAIL: 'GET_ADDRESS_SEARCH_FAIL',
}


export const SESSION_ACTIONS = {
  START: 'SESSION_ACTIONS_START',
  FINISH: 'SESSION_ACTIONS_FINISH',
  SET_EMAIL: 'SESSION_ACTIONS_SET_EMAIL'
}

export const PROMO_VERSION = {
  START: 'PROMO_VERSION',
  SUCCESS: 'PROMO_VERSION_SUCCESS',
  TRIGGER: 'PROMO_VERSION_SET_TRIGGER',
  FAIL: 'PROMO_VERSION_FAIL',
}

export const GET_SEARCH_RESULT_PROMO = {
  TRIGGER: 'GET_SEARCH_RESULT_PROMO_TRIGGER',
  SUCCESS: 'GET_SEARCH_RESULT_PROMO_SUCCESS',
  FAIL: 'GET_SEARCH_RESULT_PROMO_FAIL',
}

export const STRIPE_POST = {
  TRIGGER: 'STRIPE_POST_TRIGGER',
  SUCCESS: 'STRIPE_POST_SUCCESS',
  FAIL: 'STRIPE_POST_FAIL',
}

export const STRIPE_GET = {
  TRIGGER: 'STRIPE_GET_TRIGGER',
  SUCCESS: 'STRIPE_GET_SUCCESS',
  FAIL: 'STRIPE_GET_FAIL',
}

export const CONTACT_US = {
  TRIGGER: 'CONTACT_US_TRIGGER',
  SUCCESS: 'CONTACT_US_SUCCESS',
  FAIL: 'CONTACT_US_FAIL',
}

export const GET_CRIMINAL = {
  TRIGGER: 'GET_CRIMINAL_TRIGGER',
  SUCCESS: 'GET_CRIMINAL_SUCCESS',
  FAIL: 'GET_CRIMINAL_FAIL',
}

export const GET_CRIMINAL_SEARCH = {
  TRIGGER: 'GET_CRIMINAL_SEARCH_TRIGGER',
  SUCCESS: 'GET_CRIMINAL_SEARCH_SUCCESS',
  FAIL: 'GET_CRIMINAL_SEARCH_FAIL',
}

export const POST_CRIMINAL = {
  TRIGGER: 'POST_CRIMINAL_TRIGGER',
  SUCCESS: 'POST_CRIMINAL_SUCCESS',
  FAIL: 'POST_CRIMINAL_FAIL',
}