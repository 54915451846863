export const styles = {
  link: {
    base: 'font-primary cursor-pointer uppercase hover:text-green-5',
    active: 'text-green-5 underline',
    desktop: 'font-normal leading-3 text-lg',
    parentLinkMobile: 'text-20',
    mobileSubnavEl: 'text-16 py-6 border-b-4 w-full border-cdcdcdb text-787878',
    footer: 'text-xs uppercase',
    desktopChildrenLinkContainer:
      'absolute top-6 bg-white-primary shadow-2xl z-1 p-4 flex flex-col gap-y-4',
    desktopChildrenLink: 'text-center whitespace-nowrap',
  },
}
export const combinedStyles = {
  parentLink: ({ isActive }) =>
    `${styles.link.base} ${styles.link.desktop}
                ${isActive ? styles.link.active : ''}`,
  subnavElement: ({ isActive }) =>
    `${styles.link.base} ${styles.link.desktop} ${
      styles.link.desktopChildrenLink
    } ${isActive ? styles.link.active : ''}`,
  parentLinkMobile: ({ isActive }) => `
  ${styles.link.base} ${styles.link.parentLinkMobile}
  ${isActive ? styles.link.active : ''}`,
  subNavElementMobile: ({ isActive }) =>
    `${styles.link.base} ${styles.link.mobileSubnavEl}
    ${isActive ? styles.link.active : ''}`,
}
