const Search = ({ color, width, height, className }) => {
  return (
    <svg
      width={width || "26"}
      height={height || "26"}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || "mr-4"}
    >
      <path
        d="M25 25L18.1429 18.1429M11.2857 21.5714C5.60507 21.5714 1 16.9664 1 11.2857C1 5.60507 5.60507 1 11.2857 1C16.9664 1 21.5714 5.60507 21.5714 11.2857C21.5714 16.9664 16.9664 21.5714 11.2857 21.5714Z"
        stroke={color || "white"}
        stroke-width="2"
      />
    </svg>
  );
};

export default Search;
