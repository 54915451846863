const Handshake = ({ fill, ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 79.705 47.836'
      fill={fill || 'currentColor'}
      {...props}
    >
      <path d='M1.992 31.862A1.992 1.992 0 1 0 0 29.87a1.992 1.992 0 0 0 1.992 1.992zm76.7-23.88-14.132.026-6.379-6.214A5.927 5.927 0 0 0 53.96.038H41.386a5.924 5.924 0 0 0-3.523 1.245A6 6 0 0 0 34.216 0h-8.655a5.986 5.986 0 0 0-4.221 1.756l-6.226 6.226H1a1 1 0 0 0-1 1v1.988a1 1 0 0 0 1 1h6.968v23.888H1a1 1 0 0 0-1 1v1.992a1 1 0 0 0 1 1h6.968a3.968 3.968 0 0 0 3.972-3.95h4.134l10.148 9.712a9.988 9.988 0 0 0 12.578-.03l.9.772a6.643 6.643 0 0 0 9.326-.958l2.733-3.348a5.979 5.979 0 0 0 7.471-1.495l1.177-1.453a5.974 5.974 0 0 0 1.307-3.2h6.051a3.977 3.977 0 0 0 3.972 3.946h6.972a1 1 0 0 0 1-1v-1.992a1 1 0 0 0-1-1h-6.982V11.99l6.972-.026a1 1 0 0 0 1-1V8.978a1.01 1.01 0 0 0-1.012-.996zM57.3 36.56l-1.182 1.457a1.988 1.988 0 0 1-2.8.29h-.005l-2.221-1.792-5.166 6.35a2.632 2.632 0 0 1-3.661.424l-3.81-3.246-1.295 1.594a5.979 5.979 0 0 1-8.293.984L17.675 31.9h-5.716V11.959h4.8l7.384-7.384a2 2 0 0 1 1.408-.586H34.2c.113.273.038.087.137.36L26.993 11.1A8.542 8.542 0 1 0 38.2 23.989c.113-.1.224-.2.331-.3l2.839-2.6L57.013 33.77a2.007 2.007 0 0 1 .287 2.79zm10.433-4.669h-7.124a6.3 6.3 0 0 0-1.1-1.22L44.336 18.345l3.537-3.245a1.992 1.992 0 0 0-2.628-3l-.062.056-9.35 8.566a4.7 4.7 0 0 1-6.437-.273 4.564 4.564 0 0 1 .273-6.437l10.347-9.483a2.007 2.007 0 0 1 1.345-.523h12.575a1.984 1.984 0 0 1 1.42.6l7.558 7.355h4.818v19.93zm9.96-4.01a1.988 1.988 0 1 1 0 .009z' />
    </svg>
  )
}

export default Handshake
