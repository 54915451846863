import {
  GET_PHOTO_QUALITY,
} from "../actions/types";

const initialState = {
  matches: [],
  status: null,
  error: null,
};

export default function qualityReducer(state = initialState, { type, payload }) {
  switch (type) {
        case GET_PHOTO_QUALITY.TRIGGER: {
      return {
        ...state,
        status: "loading",
      };
    }
    case GET_PHOTO_QUALITY.SUCCESS: {
      return {
        error: null,
        matches: payload,
        status: "success",
      };
    }

    case GET_PHOTO_QUALITY.FAIL: {
      return {
        ...state,
        status: "error",
        error: payload,
      };
    }
    default:
      return state;
  }
}
