import classnames from 'classnames'
import { ErrorMessage } from 'formik'
import Text from '../../atoms/Text/Text'

const FormikFieldError = ({ name, className }) => {
  return (
    <ErrorMessage name={name}>
      {(msg) => (
        <Text
          className={classnames(
            'uppercase text-red-primary absolute -bottom-4 sm:-bottom-5',
            className
          )}
          variant='b2'
        >
          {msg}
        </Text>
      )}
    </ErrorMessage>
  )
}

export default FormikFieldError
