import { string, number } from 'prop-types';

const BellIcon = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 72 72'>
			<path
				d='M52.79,42.35a3.51,3.51,0,0,1,1.12,1.92,3.57,3.57,0,0,1-.2,2.21,3.67,3.67,0,0,1-1.4,1.73,3.81,3.81,0,0,1-2.17.64H21.86a3.81,3.81,0,0,1-2.17-.64,3.67,3.67,0,0,1-1.4-1.73,3.57,3.57,0,0,1-.2-2.21,3.51,3.51,0,0,1,1.12-1.92A11.42,11.42,0,0,0,22,38.41a22.88,22.88,0,0,0,1.13-8,11.72,11.72,0,0,1,1.52-5.83,12.8,12.8,0,0,1,4.18-4.42,12.25,12.25,0,0,1,5.86-2V16.72a1.24,1.24,0,0,1,.37-.92,1.33,1.33,0,0,1,1.84,0,1.24,1.24,0,0,1,.37.92v1.45a12.25,12.25,0,0,1,5.86,2,12.8,12.8,0,0,1,4.18,4.42,11.72,11.72,0,0,1,1.52,5.83,22.88,22.88,0,0,0,1.13,8A11.42,11.42,0,0,0,52.79,42.35Zm-2.65,3.93a1.18,1.18,0,0,0,1.2-.76A1.07,1.07,0,0,0,51,44.19a13.42,13.42,0,0,1-3.37-4.9,23.73,23.73,0,0,1-1.37-8.91,9.26,9.26,0,0,0-1.36-4.9,10,10,0,0,0-3.74-3.58,10.82,10.82,0,0,0-10.36,0,10,10,0,0,0-3.74,3.58,9.26,9.26,0,0,0-1.36,4.9,24.4,24.4,0,0,1-1.37,9A13.68,13.68,0,0,1,21,44.19a1.07,1.07,0,0,0-.32,1.33,1.18,1.18,0,0,0,1.2.76ZM36,54a2.61,2.61,0,0,0,2.57-2.58h2.57a5.14,5.14,0,0,1-10.28,0h2.57A2.61,2.61,0,0,0,36,54Z'
				fill={color}
			/>
		</svg>
	);
};
export default BellIcon;

BellIcon.defaultProps = {
	color: 'white',
	width: 25,
};

BellIcon.propTypes = {
	className: string,
	color: string,
	width: number,
};
