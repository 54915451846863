import Select from 'react-select'
// import PropTypes from 'prop-types'
import { useField } from 'formik'
import Label from './Label'
import classnames from 'classnames'
import { FormikFieldError } from '../Text'

const styles = {
  text: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: '0.688rem',
    lineHeight: '0.938rem',
    textTransform: 'uppercase',
    '@media only screen and (min-width: 640px)': {
      fontSize: '0.813rem',
      lineHeight: '1.25rem',
    },
  },
  border: {
    borderRadius: '0.375rem',
    borderWidth: 2,
    borderColor: '#cdcdcd',
  },
}

const customStyles = {
  control: (base, state) => ({
    ...base,
    ...styles.border,
    ...(state.isFocused && {
      boxShadow: '0 0 1px #2563eb',
      borderColor: '#2563eb',
    }),
    cursor: 'pointer',
    backgroundColor: '#fdfdfd',
    '&:focus-within': {
      borderColor: '#00da9d',
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#787878',
    padding: '0.25rem',
    '@media only screen and (min-width: 640px)': {
      padding: '0.5rem',
    },
  }),
  indicatorSeparator: () => ({}),
  input: (base) => ({
    ...base,
    ...styles.text,
    color: '#797979',
    padding: 0,
    margin: 0,
    cursor: 'text',
  }),
  menu: (base) => ({
    ...base,
    ...styles.border,
    margin: 0,
    backgroundColor: '#fdfdfd',
    overflow: 'hidden',
    marginTop: '-2px',
  }),
  option: (base, state) => ({
    ...base,
    ...styles.text,
    cursor: 'pointer',
    color: '#787878',
    '&:hover': {
      color: '#00da9d',
      backgroundColor: '#fdfdfd',
    },
    ...(state.isFocused && {
      backgroundColor: '#fdfdfd',
    }),
    ...(state.isSelected && {
      color: '#00da9d',
      backgroundColor: '#fdfdfd',
    }),
  }),
  placeholder: (base) => ({
    ...base,
    ...styles.text,
    color: '#797979',
    margin: 0,
  }),
  singleValue: (base) => ({
    ...base,
    ...styles.text,
    color: '#090909',
    margin: 0,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
    paddingLeft: '0.5rem',
  }),
}

export const SelectField = ({
  className,
  name,
  label,
  disabled,
  placeholder,
  options,
  required,
}) => {
  const [field, , helpers] = useField(name)

  const handleChange = ({ value }) => {
    helpers.setValue(value)
  }

  const getValue = () => options.find(({ value }) => value === field.value)

  return (
    <div className={classnames('flex flex-col relative', className)}>
      {label && (
        <Label name={name}>
          {label}
          {required && '*'}
        </Label>
      )}
      <Select
        {...field}
        placeholder={placeholder.toUpperCase()}
        options={options}
        value={getValue()}
        onChange={handleChange}
        onBlur={field.onBlur}
        isSearchable
        isDisabled={disabled}
        closeMenuOnSelect
        styles={customStyles}
      />
      <FormikFieldError name={field.name} />
    </div>
  )
}

SelectField.defaultProps = {
  options: [],
}

export default SelectField
