import {
  CONTACT_US,
} from "../actions/types";

const initialState = {
  matches: [],
  status: null,
  error: null,
};

export default function contactReducer(state = initialState, { type, payload }) {
  switch (type) {
        case CONTACT_US.TRIGGER: {
      return {
        ...state,
        status: "loading",
      };
    }
    case CONTACT_US.SUCCESS: {
      return {
        error: null,
        matches: payload,
        status: "success",
      };
    }

    case CONTACT_US.FAIL: {
      return {
        ...state,
        status: "error",
        error: payload,
      };
    }
    default:
      return state;
  }
}