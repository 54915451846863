const PersonIcon = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00033 0C5.42321 0 3.33366 2.08757 3.33366 4.66406C3.33366 7.24055 5.42321 9.32812 8.00033 9.32812C10.5774 9.32812 12.667 7.24055 12.667 4.66406C12.667 2.08757 10.5774 0 8.00033 0Z"
        fill="#00DA9D"
      />
      <path
        d="M5.33366 11.9922C2.75648 11.9922 0.666992 14.0807 0.666992 16.6582V19.9878H15.3337V16.6582C15.3337 14.0807 13.2442 11.9922 10.667 11.9922H5.33366Z"
        fill="#00DA9D"
      />
    </svg>
  );
};

export default PersonIcon;
