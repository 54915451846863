import {
  STRIPE_GET, STRIPE_POST
} from "../actions/types";

const initialState = {
  matches: [],
  searchType: null,
  status: null,
  error: null,
};

export default function stripeReducer(state = initialState, { type, payload }) {
  switch (type) {
        case STRIPE_GET.TRIGGER: {
      return {
        ...state,
        status: "loading",
      };
    }
    case STRIPE_GET.SUCCESS: {
      return {
        error: null,
        matches: payload,
        searchType: payload.searchType.name,
        status: "success",
      };
    }

    case STRIPE_GET.FAIL: {
      return {
        ...state,
        status: "error",
        error: payload,
      };
    }
    case STRIPE_POST.TRIGGER: {
      return {
        ...state,
        status: "loading",
      };
    }
    case STRIPE_POST.SUCCESS: {
      return {
        error: null,
        matches: payload,
        status: "success",
      };
    }

    case STRIPE_POST.FAIL: {
      return {
        ...state,
        status: "error",
        error: payload,
      };
    }
    default:
      return state;
  }
}