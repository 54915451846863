import { string, number } from 'prop-types';

const Bullhorn = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 121.599 94.57'>
			<defs>
				<linearGradient
					id='a'
					x1='.5'
					x2='.5'
					y2='1'
					gradientUnits='objectBoundingBox'>
					<stop offset='0' stopColor='#efea5a' />
					<stop offset='.5' stopColor='#91e474' />
					<stop offset='1' stopColor='#00da9d' />
				</linearGradient>
			</defs>
			<path
				d='M120.271 118.34a53.714 53.714 0 0 1-8-11.586c5.8-5.719 9.286-12.9 9.286-20.725 0-16.884-16.145-30.834-37.187-33.324C77.745 40.547 62.106 32 43.893 32 19.643 32-.005 47.111-.005 65.768c0 7.809 3.482 14.984 9.286 20.725a55.261 55.261 0 0 1-7.957 11.586 4.885 4.885 0 0 0 3.546 8.231 48.512 48.512 0 0 0 26.423-8.189 53.094 53.094 0 0 0 5.907 1.013c6.648 12.135 22.265 20.683 40.479 20.683a55.866 55.866 0 0 0 12.621-1.435 48.627 48.627 0 0 0 26.423 8.189 4.885 4.885 0 0 0 3.546-8.231zm-87.48-26.825-2.786-.633-2.405 1.562A43.461 43.461 0 0 1 9.091 99.3a58.711 58.711 0 0 0 6.226-9.793l2.195-4.39-3.482-3.44c-3.335-3.271-7.281-8.7-7.281-15.913 0-14.9 16.673-27.014 37.144-27.014s37.144 12.118 37.144 27.018-16.673 27.014-37.144 27.014a49.075 49.075 0 0 1-11.102-1.267zM93.953 112.7l-2.406-1.562-2.786.654a49.075 49.075 0 0 1-11.1 1.266c-13.739 0-25.748-5.466-32.164-13.57C68.986 98.818 87.79 84 87.79 65.768a26.849 26.849 0 0 0-.7-5.846c15.913 3.06 27.711 13.591 27.711 26.107 0 7.218-3.947 12.642-7.281 15.913l-3.482 3.44 2.174 4.369a59.686 59.686 0 0 0 6.226 9.793 43.475 43.475 0 0 1-18.485-6.844z'
				transform='translate(.007 -32)'
				fill={color || 'url(#a)'}
			/>
		</svg>
	);
};
export default Bullhorn;

Bullhorn.defaultProps = {
	color: undefined,
	width: 30,
};

Bullhorn.propTypes = {
	className: string,
	color: string,
	width: number,
};
