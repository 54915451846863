import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { HamburgerIcon, Close } from 'src/components/atoms/SVG'
import { NavMenuMobile } from './NavMenuMobile'
import Logo from 'src/components/atoms/Logo/Logo'
import { promoVersion } from 'src/redux/selectors/promoSelector'

export const NavMobile = ({ className }) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)
  let promoSearch = useSelector(promoVersion)
  return (
    <div className={className}>
      <div className='w-full flex justify-between items-center py-4 px-5'>
        <Logo
          className='w-32 cursor-pointer'
          onClick={() => {
            navigate('/')
            if (open) toggleOpen()
          }}
        />
        {!promoSearch && (
          <button
            className='h-8 w-8 flex justify-center items-center text-green-5'
            onClick={toggleOpen}
          >
            {open ? (
              <Close className='w-5 h-5' />
            ) : (
              <HamburgerIcon className='h-8 w-8' />
            )}
          </button>
        )}
      </div>

      {open && <NavMenuMobile onClick={toggleOpen} />}
    </div>
  )
}
