import { string, number } from 'prop-types';

const WalletIcon = ({ className, color, width }) => {
	return (
		<svg className={className} width={width} viewBox='0 0 72 72'>
			<path
				d='M52.71,23.14A3.84,3.84,0,0,1,56.57,27V48.86A5.09,5.09,0,0,1,51.43,54H23.14a7.68,7.68,0,0,1-7.71-7.71V25.71A7.68,7.68,0,0,1,23.14,18H47.57a3.84,3.84,0,0,1,3.86,3.86v1.28ZM54,48.86V27a1.25,1.25,0,0,0-1.29-1.29H24.43a1.27,1.27,0,0,1-.93-.36,1.25,1.25,0,0,1-.36-.92,1.23,1.23,0,0,1,1.29-1.29H48.86V21.86a1.26,1.26,0,0,0-.36-.93,1.27,1.27,0,0,0-.93-.36H23.14A5.23,5.23,0,0,0,18,25.71V46.29a5.23,5.23,0,0,0,5.14,5.14H51.43a2.52,2.52,0,0,0,1.81-.76A2.48,2.48,0,0,0,54,48.86ZM46.29,36a2.46,2.46,0,0,1,1.8.76,2.51,2.51,0,0,1,0,3.62,2.58,2.58,0,0,1-4.38-1.81,2.49,2.49,0,0,1,.77-1.81A2.46,2.46,0,0,1,46.29,36Z'
				fill={color}
			/>
		</svg>
	);
};

export default WalletIcon;

WalletIcon.defaultProps = {
	color: 'white',
	width: 30,
};

WalletIcon.propTypes = {
	className: string,
	color: string,
	width: number,
};
